import { styled } from '@mui/material'
import React from 'react'

import { Percentile } from '@/components/percentile'
import { Tooltip } from '@/components/tooltip'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import { calculatePercentile } from '@/utils/helpers/insights'
import theme from '@/utils/theme'

function progressBarColor (value) {
  return value > 50 ? COLORS['primary-500'] : COLORS.player3
}

export default function ShotAverageStats ({ playerStats, playerIdx }) {
  const avgServePercentile = calculatePercentile(round(playerStats[playerIdx].serve_avg_speed))
  const avgDrivePercentile = calculatePercentile(round(playerStats[playerIdx].drive_avg_speed), 'drive')
  return (
    <Container>
      <div className='percentile'>
        <div className='percentile-head'>
          <p className='body-sm bold'>Serve</p>
          <p className='body-sm'>Percentile</p>
        </div>
        <Tooltip title='Shows the player’s average serve speed for the game, compared to a 30 mph benchmark (50th percentile)'>
          <div className='statistic'>
            <p className='body-sm'>Average Serve:</p>
            <p className='body-sm bold value'>{round(playerStats[playerIdx].serve_avg_speed)}mph</p>
            <Percentile
              value={avgServePercentile}
              color={progressBarColor(avgServePercentile)}
            />
          </div>
        </Tooltip>
      </div>
      <div className='percentile'>
        <div className='percentile-head'>
          <p className='body-sm bold'>Drive</p>
          <p className='body-sm'>Percentile</p>
        </div>
        <Tooltip title='Shows the player’s average drive speed for the game, compared to a 25 mph benchmark (50th percentile)'>
          <div className='statistic'>
            <p className='body-sm'>Average Drive:</p>
            <p className='body-sm bold value'>{round(playerStats[playerIdx].drive_avg_speed)}mph</p>
            <Percentile
              value={avgDrivePercentile}
              color={progressBarColor(avgDrivePercentile)}
            />
          </div>
        </Tooltip>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  gap: theme.spacing(2),
  color: COLORS['neutral-800'],
  '& .percentile': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& .percentile-head': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${COLORS['neutral-200']}`
    }
  },
  '& .statistic': {
    ...row,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& .value': {
      marginRight: 10,
      marginLeft: 5
    },
    '& .percentile': {
      width: 90
    },
    '& strong': {
      marginRight: 5,
      marginLeft: 10
    }
  }
})
