import { Dialog, styled } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar } from '@/components/avatar'
import { BarChart } from '@/components/bar-chart'
import { ShotsDialog } from '@/components/dialog/shots-dialog'
import { PlayerImage } from '@/components/player-image'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import { column } from '@/utils/flexGrid'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'
import { shotQualityLevels } from '@/utils/shot-quality-levels'
import theme from '@/utils/theme'

function SinglePlayer (props) {
  const ref = useRef(null)
  const { playerShots, onClick, getPlayerName, playerIdx } = props

  const playerName = getPlayerName(playerIdx)

  const values = useMemo(() => {
    if (!playerShots) return []
    return [
      { value: playerShots[4].percentage, fillColor: shotQualityLevels[4].color },
      { value: playerShots[3].percentage, fillColor: shotQualityLevels[3].color },
      { value: playerShots[2].percentage, fillColor: shotQualityLevels[2].color },
      { value: playerShots[1].percentage, fillColor: shotQualityLevels[1].color },
      { value: playerShots[0].percentage, fillColor: shotQualityLevels[0].color }
    ].filter(value => value.value)
  }, [playerShots])

  if (!playerShots) {
    return `No data for ${playerName}`
  }

  return (
    <SinglePlayerContainer ref={ref}>
      <BarChart
        width={139}
        sx={{ height: '250px' }}
        withoutLabels
        isValuesRounded={false}
        values={values}
        onClick={() => onClick(playerShots, playerIdx, ref, playerName)}
        isVertical
      />
      <div className='player-info'>
        <Avatar width='35' initials={playerName} playerId={playerIdx} className='avatar' />
        <PlayerImage className={cls('img', `player${playerIdx + 1}`)} width={90} height={55} numberOfImages={4} imageWidth={25} playerIdx={playerIdx} />
        <p className='title-lg truncate'>{playerName}</p>
      </div>
    </SinglePlayerContainer>

  )
}

export default function AllPlayerPerformanceCharts (props) {
  const { vid, shotsByQualityPerPlayer, getPlayerName } = props
  const isMobile = useMobileDetect('sm')
  const navigate = useNavigate()

  const [dialogData, setDialogData] = useState(null)
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 })

  function createExploreUrl (minQuality, maxQuality, playerId) {
    const query = {
      quality: {
        min: minQuality,
        max: maxQuality
      },
      players: [playerId]
    }

    // Construct the full URL
    const url = `explore?filters=${encodeURI(JSON.stringify(query))}`
    return url
  }

  function onRedirectToShotExplorer (level, playerIdx) {
    const step = 0.2
    const minQuality = step * level
    const maxQuality = minQuality + step

    const url = createExploreUrl(minQuality.toFixed(1), maxQuality.toFixed(1), playerIdx)
    scrollToTopOfMain()
    navigate(`/video/${vid}/${url}`)
  }

  function handleSingleChartClick (playerShots, playerIdx, ref, playerName) {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const qualityKeys = Object.keys(shotQualityLevels)
      // Buttons are in reverse sequence so we need to invert the index value
      const totalLevels = qualityKeys.length - 1

      const buttonsForShotDialog = qualityKeys.reverse().map(level => {
        return {
          color: shotQualityLevels[level].color,
          label: shotQualityLevels[level].label,
          shotCount: playerShots[level].count,
          callback: (idx, playerIdx) => onRedirectToShotExplorer(totalLevels - idx, playerIdx)
        }
      })

      // Calculate position above the clicked element
      setDialogPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      })
      setDialogData({ buttonsForShotDialog, playerIdx, playerName })
    }
  }

  function onCloseDialog () {
    setDialogData(null)
  }

  const dialogDesktopStyle = {
    position: 'absolute',
    top: '50%', // Vertically center the dialog
    left: dialogPosition.left + (dialogPosition.width / 2), // Horizontally position based on clicked element
    transform: 'translateY(-50%) translateX(-50%)',
    margin: 0
  }

  if (!shotsByQualityPerPlayer) {
    return 'Loading...'
  }

  return (
    <Container>
      <div className='content'>
        <SinglePlayer playerShots={shotsByQualityPerPlayer[0]} playerIdx={0} onClick={handleSingleChartClick} getPlayerName={getPlayerName} />
        <SinglePlayer playerShots={shotsByQualityPerPlayer[1]} playerIdx={1} onClick={handleSingleChartClick} getPlayerName={getPlayerName} />
        <SinglePlayer playerShots={shotsByQualityPerPlayer[2]} playerIdx={2} onClick={handleSingleChartClick} getPlayerName={getPlayerName} />
        <SinglePlayer playerShots={shotsByQualityPerPlayer[3]} playerIdx={3} onClick={handleSingleChartClick} getPlayerName={getPlayerName} />
      </div>
      <Dialog
        onBackdropClick={onCloseDialog}
        open={Boolean(dialogData)}
        PaperProps={{
          style: !isMobile ? dialogDesktopStyle : {}
        }}
      >
        <ShotsDialog buttons={dialogData?.buttonsForShotDialog} onClose={onCloseDialog} playerIdx={dialogData?.playerIdx} playerName={dialogData?.playerName} />
      </Dialog>
    </Container>
  )
}

const SinglePlayerContainer = styled('div')({
  ...column,
  cursor: 'pointer',
  position: 'relative',
  gap: theme.spacing(1),
  alignItems: 'center',
  '& .player-info': {
    ...column,
    gap: theme.spacing(1),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& .avatar': {
      marginBottom: -10,
      zIndex: 10
    }
  }
})

const Container = styled('div')({
  ...column,
  gap: theme.spacing(2),

  '& .content': {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(2)
  },
  [theme.breakpoints.down('md')]: {
    '& .content': {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
    }
  }
})
