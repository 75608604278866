import { styled } from '@mui/material/styles'
import clsx from 'clsx'

import { BarChart } from '../bar-chart'

import explodeImgUrl from '@/assets/explode.png'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'

/**
 * Absolutely positioned Explode icon
 *
 * @returns {React.ReactElement}
 */
const ExplodeIcon = styled('div')({
  position: 'absolute',
  background: `url(${explodeImgUrl}) no-repeat center center`,
  backgroundSize: 'cover',
  height: 30,
  width: 30,
  right: -15,
  top: '50%',
  transform: 'translateY(-50%)'
})

/**
 * CourtKitchen container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...column,
  alignSelf: 'center',
  flex: 1,

  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .grow': {
    flexGrow: 1
  },

  '& .court': {
    border: '2px solid black',
    aspectRatio: '1.82',
    width: '100%',

    '& > div.column:first-of-type, & > div.column:last-of-type': {
      width: '35%'
    },
    '& > .column.grow > div': {
      border: 'none'
    },
    // Style left side of the court
    '& > .column:first-of-type': {
      '& .half-h': {
        borderRight: '2px solid black',
        '&:first-of-type': {
          borderBottom: '1px solid black'
        },
        '&:last-of-type': {
          borderTop: '1px solid black'
        }
      }
    },
    // Style right side of the court
    '& > .column:last-of-type': {
      '& .half-h': {
        borderLeft: '2px solid black',
        '&:first-of-type': {
          borderBottom: '1px solid black'
        },
        '&:last-of-type': {
          borderTop: '1px solid black'
        }
      }
    }
  },
  '& .net': {
    position: 'relative',
    width: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.04)',
      top: -12,
      left: 2,
      bottom: -12,
      width: 13,
      transform: 'skew(0, 30deg)',
      transformOrigin: 'top left',
      filter: 'blur(2px)'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      border: '2px solid black',
      background: '#fff',
      borderRadius: 10,
      top: -12,
      left: -2,
      bottom: -12,
      width: 5
    }
  },
  '& .player1, & .player2, & .player3, & .player4': {
    justifyContent: 'center',
    margin: '0 10px',
    fontSize: 16,

    '& > div': {
      marginBottom: 5,
      textAlign: 'center'
    }
  },
  '& .half-h': {
    height: '50%'
  },
  '& .label': {
    ...row,
    alignSelf: 'center',
    color: COLORS['00-on-surface-high-emphasis'],
    marginTop: 20,
    '& svg': {
      marginRight: 4
    }
  },
  '& .relative': {
    position: 'relative' // Needed for explode image
  }
})

/**
 * Graphic representation of the playground with percentages on each side showing player stats
 *
 * @exports
 * @param props {object} { player1, player2, player3, player4, className }
 * @returns {React.ReactElement}
 */
export function CourtKitchen (props) {
  const { values, className } = props
  const [player1, player2, player3, player4] = values

  return (
    <Container className={clsx(['court-kitchen', className])}>
      <div className='row grow'>
        <div className='court row grow'>
          <div className='column'>
            <div className='half-h row relative'>
              <BarChart values={[{ value: player1, fillColor: COLORS.player1 }]} single hasRoundedBorders={false} />
              {player1 > 94 && <ExplodeIcon />}
            </div>
            <div className='half-h row relative'>
              <BarChart values={[{ value: player2, fillColor: COLORS.player2 }]} single hasRoundedBorders={false} />
              {player2 > 94 && <ExplodeIcon />}
            </div>
          </div>
          <div className='column grow' />
          <div className='column net' />
          <div className='column grow' />
          <div className='column'>
            <div className='half-h row relative'>
              <BarChart values={[{ value: player3, fillColor: COLORS.player3 }]} single reversed hasRoundedBorders={false} />
              {player3 > 94 && <ExplodeIcon />}
            </div>
            <div className='half-h row relative'>
              <BarChart values={[{ value: player4, fillColor: COLORS.player4 }]} single reversed hasRoundedBorders={false} />
              {player4 > 94 && <ExplodeIcon />}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
