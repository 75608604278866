import { useEffect, useMemo, useState } from 'react'

export const isProd = import.meta.env.VITE_PROJECT_ID === 'pbv-prod'
export const isDebugMode = !isProd || window.location.hostname.startsWith('localhost')

export function assert (expr, msg, context = null) {
  if (!expr) {
    if (context) {
      console.error(msg, context)
    }
    throw new Error(`assert failed${msg ? `: ${msg}` : ''}`)
  }
}
assert.fail = (msg, context = null) => assert(false, msg, context)

/** Returns the current millisecond epoch */
export const millinow = () => new Date().getTime()

/** Returns the current epoch */
export const now = (isFloored = false) => {
  const epoch = millinow() / 1000
  return isFloored ? Math.floor(epoch) : epoch
}

export async function sleep (millis) {
  return new Promise(resolve => setTimeout(resolve, millis))
}

export function useCurrentEpochAndUpdateWhenNeeded () {
  const [epoch, setEpoch] = useState(now())
  const [epochWhenUpdateNeeded, setEpochWhenUpdateNeeded] = useState()

  // Update the epoch when needed -- epoch will be updated once the user subscription ends
  useEffect(() => {
    if (!epochWhenUpdateNeeded || epochWhenUpdateNeeded <= epoch) {
      return
    }
    const timer = setTimeout(() => {
      setEpoch(now())
    }, (epochWhenUpdateNeeded - now()) * 1000)
    return () => clearTimeout(timer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epochWhenUpdateNeeded])

  return useMemo(() => ({
    epoch,
    setEpochWhenUpdateNeeded
  }), [epoch])
}
