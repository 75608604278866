import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Button, FormControl, FormHelperText } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import debounce from 'debounce'
import { useEffect, useRef, useState, useCallback, useMemo, useContext } from 'react'
import { useSearchBox as useAlgoliaSearch, useHits as useAlgoliaResults } from 'react-instantsearch'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { PlayerImage } from '@/components/player-image'
import { getDefaultPlayerName } from '@/components/tag-player'
import { AddUserEmailPopup } from '@/components/tag-player/add-user-email-popup'
import { AddUserPhonePopup } from '@/components/tag-player/add-user-phone-popup'
import { InviteUserPopup } from '@/components/tag-player/invite-user-popup'
import { Container, AutocompletePaper, AutocompleteOption } from '@/components/tag-player/tag-player-popup/containers'
import { useAvatar } from '@/hooks/use-avatar'
import { selectLoggedInUser } from '@/store/auth'
import { tagUser, removeTagFromRecent } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { useGetVideo } from '@/store/video'
import { APIContext } from '@/utils/api'
import { copyToClipboard } from '@/utils/helper'

const SHOW_UIDS_SEARCH_PARAM_NAME = 'showUIDs'

/**
 * TagPlayerPopup component
 *
 * @exports
 * @param props {object} {anchorEl, open, onClose, onOpenSelfTagPopup, vid, playerIdx, initialName, playerName, aiEngineVersion}
 * @returns {React.ReactElement}
 */
export function TagPlayerPopup (props) {
  const dispatch = useDispatch()
  const { anchorEl, open, vid, playerIdx, initialName, playerName, aiEngineVersion } = props
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [displayError] = useState(false)
  const openSnackbar = useSnackbar()
  const inputRef = useRef(null)
  const user = useSelector(selectLoggedInUser)
  const { video } = useContext(APIContext)
  const { userData } = video
  const videoDetails = useGetVideo(vid)
  const { UserAvatar, hasAvatar } = useAvatar()
  const [searchParams] = useSearchParams()

  const [autocompleteListOpened, setAutocompleteListOpened] = useState(false)
  const [inviteUserPopupOpened, setInviteUserPopupOpened] = useState(false)
  const [addUserEmailPopupOpened, setAddUserEmailPopupOpened] = useState(false)
  const [addUserPhonePopupOpened, setAddUserPhonePopupOpened] = useState(false)

  const { refine: searchAlgolia } = useAlgoliaSearch()
  const { items: algoliaResults } = useAlgoliaResults()

  const [filteredRecentlyTagged, setFilteredRecentlyTagged] = useState([])

  const options = useMemo(() => {
    const items = []
    const hasTaggedThemself = !!videoDetails.data?.userData?.players.find((player) =>
      (!!player?.uid && !!user?.uid) && (player.uid === user.uid))
    let selfTagOption = {}
    if (!hasTaggedThemself) {
      if (user.displayName) {
        selfTagOption = {
          name: user.displayName,
          uid: user.uid,
          _meta: {
            thisIsMe: true,
            subtext: 'This is me!'
          }
        }
        if ((name === getDefaultPlayerName(playerIdx)) || (user.displayName.toLowerCase().indexOf(name.toLowerCase()) !== -1)) {
          items.push(selfTagOption)
        }
      } else {
        selfTagOption = {
          name: 'This is me',
          uid: user.uid,
          _meta: {
            subtext: 'Click to set your name',
            playerInitials: 'Me',
            thisIsMeNoName: true
          }
        }
        if ((name === getDefaultPlayerName(playerIdx)) || !name) {
          items.push(selfTagOption)
        }
      }
    }
    if (filteredRecentlyTagged.length) {
      items.push(...filteredRecentlyTagged.map((item) => ({ ...item, _meta: { isRecentlyTagged: true } })))
    } else if (algoliaResults.length) {
      items.push(...algoliaResults.map((item) => ({ ...item, _meta: { isAlgoliaResult: true } })))
    }

    return items.map((item, index) => {
      const current = { ...item }
      let _meta = current._meta
      if (!_meta) {
        current._meta = {}
        _meta = current._meta
      }
      _meta.index = index
      return current
    })
  }, [filteredRecentlyTagged, algoliaResults, user.displayName, name, user.uid, videoDetails.data?.userData?.players, playerIdx])

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  const doTagUser = ({ newName, uid, algoliaAddrId, payload }) => {
    if (!isNameValid(newName)) return
    if (newName !== playerName) {
      const value = newName.trim()
      const objToDispatch = { ...payload, vid, playerIdx, name: value }
      if (uid) {
        objToDispatch.uid = uid
      } else if (algoliaAddrId) {
        objToDispatch.algoliaAddrId = algoliaAddrId
      }
      dispatch(tagUser(objToDispatch))
    }
  }

  const openAutocompleteList = () => {
    setAutocompleteListOpened(true)
    inputRef.current?.focus()
    inputRef.current?.select()
    inputRef.current?.setSelectionRange(0, 9999) // iOS fix
  }

  const onOpen = useCallback(() => {
    setName(playerName || '')
    setTimeout(() => openAutocompleteList(), 0)
  }, [playerName])

  useEffect(() => {
    if (open) {
      onOpen()
    }
  }, [open, onOpen])

  // const normalizeUrl = (url) =>
  //   url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const debouncedAlgoliaSearch = useMemo(() =>
    debounce((value) => {
      searchAlgolia(value)
    }, 300),
  [searchAlgolia])

  const isAlreadyTagged = useCallback((player) => {
    return userData.players.some((taggedPlayer) => {
      if (!!taggedPlayer.uid && !!player.uid) {
        return taggedPlayer.uid === player.uid
      } else if (!taggedPlayer.uid && !player.uid) {
        return taggedPlayer.name === player.name
      }
      return false
    })
  }, [userData.players])

  const filterName = useCallback((value) => {
    const tempFilteredRecentlyTagged = (user.recentlyTagged ?? []).filter((option) => {
      const notMyself = option.uid !== user.uid
      const notAlreadyTagged = isAlreadyTagged(option)
      const matchesSearchString = option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      return notMyself && !notAlreadyTagged && matchesSearchString
    })
    setFilteredRecentlyTagged(tempFilteredRecentlyTagged)
    if (!tempFilteredRecentlyTagged.length) {
      debouncedAlgoliaSearch(value)
    }
  }, [user.recentlyTagged, user.uid, debouncedAlgoliaSearch, isAlreadyTagged])

  const onInputChange = (event, value) => {
    setName(value)
    filterName(value)
  }

  const isNameValid = (value) => {
    const trimmed = value?.trim()
    return trimmed?.length >= 2
  }

  useEffect(() => {
    setError(!isNameValid(name) ? 'Player Name must be at least 2 characters long' : '')
  }, [name])

  const onInputKeyDown = (event) => {
    const { key } = event
    if (key === 'Enter' && !options.length) {
      handleAddNew()
    }
  }

  const renderAutocompleteInput = (params) => (
    <FormControl error={!!error}>
      <TextField
        autoFocus
        className='text-field'
        placeholder='Type a Name'
        inputRef={inputRef}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
          autoCapitalize: 'words' // this will activate title-case-mode on iOS Safari
        }}
        InputProps={{
          ...params.InputProps,
          onKeyDown: onInputKeyDown,
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon className='icon' />
            </InputAdornment>
          )
        }}
      />
      {displayError && !!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )

  const handleAddNew = (event) => {
    setAutocompleteListOpened(false)
    props.onClose()
    setInviteUserPopupOpened(true)
  }

  const renderAutocompletePaper = (props) => (
    <AutocompletePaper {...props}>
      <div>{props.children}</div>
      <div
        className='footer' onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className='info-section'>
          {!options.length && (
            <>
              <div className='no-matching-names-text'>No matching names</div>
              <div className='name'>{name}</div>
            </>
          )}
        </div>
        <Button onClick={handleAddNew} className='add-new' variant='outlined'>
          {options.length ? 'Invite' : 'Add'}
        </Button>
      </div>
    </AutocompletePaper>
  )

  const generatePlayerInitials = (option) => {
    const { name } = option
    const playerInitials = option._meta?.playerInitials
    if (playerInitials) {
      return playerInitials
    } else {
      const tokens = name.split(' ').filter(name => name) // remove empty strings (fix for already entered names with multiple spaces)
      return tokens.length === 1
        ? tokens[0].substring(0, 2).toUpperCase()
        : `${tokens[0][0].toUpperCase()}${tokens[1][0].toUpperCase()}`
    }
  }

  const renderOptionAvatar = (option) => {
    const isLoggedInAvatar = (option._meta?.thisIsMe || option._meta?.thisIsMeNoName) && hasAvatar()
    if (isLoggedInAvatar || !!option.image) {
      return <UserAvatar className='avatar-image' imageUrl={option.image} />
    } else {
      return (
        <div className='avatar-initials'>
          {generatePlayerInitials(option)}
        </div>
      )
    }
  }

  const onRemoveRecentlyTaggedEntry = (event, option) => {
    event.stopPropagation()
    const optionToRemove = { ...option }
    stripOptionMetaProps(optionToRemove)
    dispatch(removeTagFromRecent({ recentlyTaggedEntry: optionToRemove }))
  }

  const copyValueToClipboard = async (value, successText) => {
    copyToClipboard(value)
      .then(() => {
        openSnackbar(successText)
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const onCopyUid = (event, uid) => {
    event.stopPropagation()
    event.preventDefault()
    copyValueToClipboard(uid, 'Successfully copied UID to clipboard')
  }

  const onCopyAlgoliaId = (event, algId) => {
    event.stopPropagation()
    event.preventDefault()
    copyValueToClipboard(algId, 'Successfully copied Algolia ID to clipboard')
  }

  const renderAutocompleteOption = (renderProps, option) => {
    const { key, ...optionProps } = renderProps
    const subtext = option._meta?.subtext || (option.objectID ? 'PBV user' : 'Recently tagged')
    const isRemovable = !option._meta.thisIsMe && !option._meta.thisIsMeNoName && option._meta.isRecentlyTagged

    const showUserIds = searchParams.get(SHOW_UIDS_SEARCH_PARAM_NAME) !== null
    let uid, algoliaAddrId
    const objectID = option.objectID
    if (objectID?.startsWith('alg')) {
      algoliaAddrId = objectID.substring(2)
    } else {
      uid = objectID
    }

    return (
      <AutocompleteOption
        key={key}
        component='li'
        {...optionProps}
      >
        <div className='option-content'>
          {renderOptionAvatar(option)}
          <div className='name-section'>
            <span className='name'>
              {option.name}
            </span>
            <span className='info'>
              <span className='subtext'>{subtext}</span>
              {showUserIds && (
                <div className='id-container'>
                  {!!uid && <span onClick={(e) => onCopyUid(e, uid)}>Copy UID</span>}
                  {!!algoliaAddrId && <span onClick={(e) => onCopyAlgoliaId(e, algoliaAddrId)}>Copy Algolia ID</span>}
                </div>
              )}
            </span>
          </div>
          {isRemovable &&
            <CloseIcon
              className='remove-recently-tagged'
              onClick={(event) => onRemoveRecentlyTaggedEntry(event, option)}
            />}
        </div>
      </AutocompleteOption>
    )
  }

  const stripOptionMetaProps = (option) => {
    const propsToStrip = ['_meta', 'index']
    propsToStrip.forEach((prop) => delete option[prop])
  }

  const tagRecentlyTaggedPlayer = (option) => {
    const newValue = option.name
    if (isNameValid(newValue)) {
      let payload = {}
      if (option._meta?.isRecentlyTagged) {
        payload = { ...option }
        stripOptionMetaProps(payload)
      }
      doTagUser({ newName: newValue, payload })
    }
  }

  const tagAlgoliaUser = (option) => {
    const newValue = option.name
    if (isNameValid(newValue)) {
      let uid, algoliaAddrId
      const objectID = option.objectID
      if (objectID?.startsWith('alg:')) {
        algoliaAddrId = objectID.substring(4)
      } else {
        uid = objectID
      }
      doTagUser({ newName: newValue, uid, algoliaAddrId })
    }
  }

  const tagMyself = (option) => {
    const newValue = option.name
    if (isNameValid(newValue)) {
      doTagUser({ newName: newValue, uid: option.uid })
    }
  }

  const onOptionChange = (event, option) => {
    const newValue = option.name || name
    setName(newValue)
    if (option._meta?.isRecentlyTagged) {
      tagRecentlyTaggedPlayer(option)
    } else if (option._meta?.isAlgoliaResult) {
      tagAlgoliaUser(option)
    } else if (option._meta?.thisIsMe) {
      tagMyself(option)
    } else if (option._meta?.thisIsMeNoName) {
      props.onOpenSelfTagPopup()
    }
    props.onClose()
  }

  const renderContent = () => (
    <>
      <div className='header'>
        <span className='title'>Tag Player</span>
        <CloseIcon
          className='close-icon' onClick={() => {
            props.onClose()
          }}
        />
      </div>
      <div className='content'>
        <PlayerImage playerIdx={playerIdx} numberOfImages={4} height={68} width={120} />
        <div className='avatar'>P</div>
        <Autocomplete
          autoHighlight
          open={autocompleteListOpened}
          clearOnBlur={false}
          inputValue={name}
          onChange={onOptionChange}
          getOptionKey={(option) => option._meta.index}
          getOptionLabel={(option) => option.name || option || ''}
          onInputChange={onInputChange}
          renderInput={renderAutocompleteInput}
          renderOption={renderAutocompleteOption}
          slotProps={{
            paper: {
              component: renderAutocompletePaper
            }
          }}
          filterOptions={() => options}
          options={options}
        />
      </div>
    </>
  )

  useEffect(() => {
    if (open) {
      filterName(name)
    }
  }, [open, filterName, name])

  const renderInviteUserPopup = () => (
    <InviteUserPopup
      open={inviteUserPopupOpened}
      anchorEl={anchorEl}
      playerName={name || initialName}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={() => { setInviteUserPopupOpened(false) }}
      onAddUserEmail={(opts) => {
        setName(opts.name)
        setTimeout(() => {
          setInviteUserPopupOpened(false)
          setAddUserEmailPopupOpened(true)
        }, 0)
      }}
      onAddUserPhone={(opts) => {
        setName(opts.name)
        setTimeout(() => {
          setInviteUserPopupOpened(false)
          setAddUserPhonePopupOpened(true)
        }, 0)
      }}
    />
  )

  const renderAddUserPhonePopup = () => (
    <AddUserPhonePopup
      open={addUserPhonePopupOpened}
      anchorEl={anchorEl}
      playerName={name}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={() => { setAddUserPhonePopupOpened(false) }}
    />
  )

  const renderAddUserEmailPopup = () => (
    <AddUserEmailPopup
      open={addUserEmailPopupOpened}
      anchorEl={anchorEl}
      playerName={name}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={() => { setAddUserEmailPopupOpened(false) }}
    />
  )

  return (
    <>
      <Container
        open={open}
        onClose={() => props.onClose()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transitionDuration={0}
      >
        {renderContent()}
      </Container>
      {renderInviteUserPopup()}
      {renderAddUserEmailPopup()}
      {renderAddUserPhonePopup()}
    </>
  )
}
