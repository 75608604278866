import { styled } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import AllPlayersCourtKitchenChart from '../../components/all-players-court-kitchen-chart'
import ShotDistributionCharts from '../../components/shot-distribution-charts'

import { SkeletonWrapper } from '@/components/skeleton'
import ProcessingProgress from '@/components/video-player/processing-progress'
import { APIContext } from '@/utils/api'

const SERVING_TITLE = <>Kitchen Arrival: <b>When Serving</b></>
const SERVING_TOOLTIP = 'Shows how often a player reaches the kitchen line during rallies, counted only when their side is serving.'
const RETURNING_TITLE = <>Kitchen Arrival: <b>When Returning</b></>
const RETURNING_TOOLTIP = 'Shows how often a player reaches the kitchen line during rallies, counted only when their side is returning.'

export default function TeamStatsTab () {
  const { insights, workflow } = useContext(APIContext)
  const isWorkflowDone = Boolean(workflow?.epochFinished)
  const params = useParams()
  const vid = params.vid

  return (
    <Container>
      <div className='insights-group'>
        <h1>Team Stats</h1>
        <div className='insights-row-grid'>
          {workflow && !isWorkflowDone && (
            <ProcessingProgress
              vid={vid}
              workflowId={workflow.workflowId}
              workflowIndex={0}
              className='full'
            />
          )}
          <SkeletonWrapper isLoading={!isWorkflowDone && !insights} height={300} rootClassName='full'>
            <AllPlayersCourtKitchenChart className='full' title={SERVING_TITLE} tooltip={SERVING_TOOLTIP} playerData={insights?.player_data} isReturning={false} />
          </SkeletonWrapper>
          <SkeletonWrapper isLoading={!isWorkflowDone && !insights} height={300} rootClassName='full'>
            <AllPlayersCourtKitchenChart className='full' title={RETURNING_TITLE} tooltip={RETURNING_TOOLTIP} playerData={insights?.player_data} isReturning />
          </SkeletonWrapper>
        </div>
        <p className='title-lg'>Shot Distribution and Stacking</p>
        <div className='insights-row-grid'>
          <SkeletonWrapper isLoading={!isWorkflowDone && !insights} height={170} rootClassName='full'>
            <div className='full'>
              <ShotDistributionCharts playerData={insights?.player_data} />
            </div>
          </SkeletonWrapper>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({})
