import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { useMemo } from 'react'

import { SimpleButton } from '@/components/button/simple-button'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

export const chipStyles = {
  '&.MuiStack-root': {
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%'
  },
  '& .MuiChip-root': {
    backgroundColor: '#fff',
    border: `1px solid ${COLORS['neutral-300']}`,
    fontSize: 16,
    fontWeight: 500,
    marginLeft: '0 !important',
    '&.MuiChip-outlined': {
      color: '#fff',
      backgroundColor: COLORS['neutral-500'],
      '& .badge': {
        backgroundColor: COLORS['neutral-700'],
        color: '#fff'
      },
      '& .label-badge': {
        display: 'flex'

      },
      '& .label': {
        color: COLORS.white,
        display: 'block',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'

      },
      '& svg': {
        color: '#fff',
        marginLeft: 'auto'
      }
    }
  },
  '& .MuiChip-label': { display: 'flex' },
  '& .badge': {
    display: 'block',
    color: '#000',
    background: COLORS['neutral-200'],
    borderRadius: '100%',
    minWidth: '1.7em',
    lineHeight: '142%',
    fontSize: 12,
    alignSelf: 'center',
    marginLeft: '4px',
    textAlign: 'center',
    padding: '2px'
  }
}

export function ChipSelect (props) {
  const { className, title, onChange, badge, selected = [], options = [], disabled = [], toggle = false, isSwitch, style } = props
  const optionsIsObject = useMemo(() => {
    return options.length && Object.prototype.toString(options[0]) === '[object Object]'
  }, [options])

  const handleSelect = (rec, force = false) => {
    if (Array.isArray(selected)) {
      if (selected.includes(rec)) {
        if (toggle || force) {
          onChange(selected.filter((r) => r !== rec))
        }
      } else if (isSwitch) {
        onChange([rec])
      } else {
        onChange([...selected, rec])
      }
    } else {
      // Fallback (this should not happen - selected default fallback is empty array)
      onChange([rec])
    }
  }

  const selectAll = () => {
    onChange(selected.length === options.length ? [] : optionsIsObject ? options.map((o) => o.value) : options)
  }

  return (
    <div style={style}>
      {title && <div className='title'>{title}<SimpleButton onClick={selectAll}>{selected.length === options.length ? 'Deselect all' : 'Select all'}</SimpleButton></div>}
      <section className={cls(!title && 'with-margin', className)}>
        <Stack direction='row' spacing={1} sx={chipStyles}>
          {options.map((option) => {
            let label = option?.label || option
            const value = option?.value || option
            if (badge?.[value]) {
              label = <>{label}<span className='badge'>{badge[value]}</span></>
            }
            return (
              <Chip
                variant={selected.includes(value) ? 'outlined' : 'filled'}
                label={label}
                key={value}
                onDelete={selected.includes(value) ? () => handleSelect(value, true) : undefined}
                onClick={() => handleSelect(value)}
                disabled={!selected.includes(value) && (disabled.includes(value) || (badge && !badge?.[value]))}
              />
            )
          }
          )}
        </Stack>
      </section>
    </div>
  )
}
