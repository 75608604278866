import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { createContext, forwardRef, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PageContext } from '../context'
import { useMoveToFolder } from '../hooks/use-move-to-folder'

import FolderIcon from '@/assets/folder-small-icon.svg?react'
import { Button } from '@/components/button'
import useKeypress from '@/hooks/use-keypress'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

const FoldersContext = createContext(null)

function SingleFolder (props) {
  const { setSelectedFolder, selectedFolder } = useContext(FoldersContext)
  const isMobile = useMobileDetect()

  const selected = selectedFolder?.fid === props.fid
  const disabled = props.disabledSubs || props.disabled

  return (
    <StyledFolder className={cls(selected && 'selected', disabled && 'disabled')} onClick={() => setSelectedFolder(props)} style={{ marginLeft: `${props.level * 10}px`, ...props.style }}>
      <FolderIcon />
      <p>{props.name}</p>
      {(!selected || disabled) && !isMobile &&
        <Button className='gray' onClick={() => setSelectedFolder(props)}>Move</Button>}
    </StyledFolder>
  )
}

function FolderTree (props) {
  const params = useParams()
  const { itemsForMoving } = useContext(PageContext)

  let disabledSubs = props?.disabledSubs || false

  // Decide if a folder should be disabled for moving into
  const rules = (item, folder) => {
    if (item.fid) {
      if (item.fid === folder.fid) {
        return true
      }
    }
    if (!item.fid && folder.fid === null) {
      return true
    }

    // If item is folder
    if (item.fid && !item.vid) {
      if (item.fid === folder.parent) {
        disabledSubs = true
        return true
      }
      // disable moving in current folder (where item already is)
      if (params?.fid && Number(params.fid) === folder.fid) {
        return true
      }
    }

    return false
  }

  const disabled = itemsForMoving.some(item => rules(item, props))

  const renderChildren = (children, level, disabledSubs) => {
    return children.map((child, i) => (
      <FolderTree
        {...child}
        key={i}
        level={level}
        disabledSubs={disabledSubs}
      />
    ))
  }

  return (
    <StyledTree>
      <SingleFolder
        {...props}
        level={props.level}
        selectedFolder={props.selectedFolder}
        onSelectFolder={props.onSelectFolder}
        disabled={disabled}
        disabledSubs={disabledSubs}
      />
      {props.children && props.children.length > 0 && (
        <div>
          {renderChildren(props.children, props.level + 1, disabledSubs)}
        </div>
      )}
    </StyledTree>
  )
}

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function SelectFolderDialog (props) {
  const { moveItemsIsOpen, setIsMoveItemsOpen, nestedFolders, itemsForMoving, setItemsForMoving, setBulkSelected } = useContext(PageContext)
  const { moveItemsToFolder } = useMoveToFolder()
  const params = useParams()
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const isOpen = useMemo(() => {
    return !!moveItemsIsOpen
  }, [moveItemsIsOpen])

  const context = useMemo(() => {
    return {
      selectedFolder,
      setSelectedFolder
    }
  }, [selectedFolder])

  const handleClose = () => {
    setIsMoveItemsOpen(false)
    setItemsForMoving([])
    setSelectedFolder()
  }

  const handleAccept = async () => {
    try {
      setIsLoading(true)
      await moveItemsToFolder(itemsForMoving, selectedFolder.fid)
      setBulkSelected([])
      openSnackbar('Successfully moved.')
    } catch (error) {
      openSnackbar('An error occurred while moving item. Please try again.', 'error')
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  useKeypress('Enter', () => {
    moveItemsIsOpen && handleAccept()
  })

  const isDefaultLibraryFolderAllowed = params?.vid ? itemsForMoving[0]?.fid !== null : params?.fid

  return (
    <FoldersContext.Provider value={context}>
      <StyledDialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={`content ${isMobile && 'mobile'}`}>
          <div className='head'>
            <p>Move {itemsForMoving.length} {itemsForMoving.length > 1 ? 'items' : 'item'} to{!selectedFolder ? '...' : ` ${selectedFolder.name}`}</p>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className='folders'>
            <SingleFolder name='My Video Library' fid={null} style={{ marginBottom: '10px' }} disabled={!isDefaultLibraryFolderAllowed} />
            {nestedFolders?.length && nestedFolders.map((folder, i) => (
              <FolderTree key={i} {...folder} />
            ))}
          </div>
          <div className='actions'>
            <Button className='gray no-outline' onClick={handleClose}>Cancel</Button>
            <Button
              className={((selectedFolder || isLoading)) ? 'green' : 'gray-filled'} disabled={(!selectedFolder || isLoading)} onClick={handleAccept}
            >Move
            </Button>
          </div>
        </div>
      </StyledDialog>
    </FoldersContext.Provider>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    margin: 0
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 24px',
    width: '500px',

    '& .folders': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '70vh',
      overflowY: 'auto'
    },

    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        cursor: 'pointer'
      },
      '& p': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: COLORS['neutral-800']
      }
    },
    '& input': {
      padding: '8px'
    },
    '& .actions': {
      display: 'flex',
      gap: '8px',
      justifyContent: 'flex-end'
    },

    '&.mobile': {
      width: '90vw'
    }
  }

}))
const StyledTree = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const StyledFolder = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '50px',
  padding: '8px 16px',
  gap: '8px',
  border: `1px solid ${COLORS['neutral-200']}`,
  transition: '300ms',
  '& svg': {
    color: '#cdd7e1'
  },
  '& button': {
    opacity: 0,
    marginLeft: 'auto',
    transition: '300ms'
  },
  '&:hover': {
    '& button': {
      opacity: 1
    }
  },
  '& p': {
    textWrap: 'nowrap',
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  '&.selected': {
    backgroundColor: COLORS['neutral-500'],
    '& p': {
      color: COLORS.white
    }
  },
  '&.disabled': {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}))
