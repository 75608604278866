import { styled } from '@mui/material'
import React from 'react'

import StatsSectionTitle from '../components/stats-section-title'

import { CourtCoverage } from '@/components/court-coverage'
import { Divider } from '@/components/divider'
import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import theme from '@/utils/theme'

export default function CourtCoverageGraph ({ playerIdx, insights }) {
  if (!insights) {
    return null
  }

  return (
    <Container>
      <div className='graph-description'>
        <StatsSectionTitle tooltip='Total distance in feet the player moved across the court during the game.' title='Court Coverage' />
        <p className='body-xs' color={COLORS['neutral-500']}>
          Game Overview
        </p>
        <Divider />
        <p className='body-sm'>
          Distance Covered: {round(insights.player_data[playerIdx].court_coverage.total_distance_covered)} ft.
        </p>
      </div>
      <div className='graph'>
        <CourtCoverage data={insights.player_data[playerIdx].court_coverage.heat_map_coordinates} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  gap: theme.spacing(4),
  '& .graph-description': {
    ...column,
    width: 200,
    minWidth: 200,
    color: COLORS.black,
    gap: theme.spacing(1)
  },
  '& .graph': {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  [theme.breakpoints.down('md')]: {}
})
