import { ClickAwayListener } from '@mui/base'
import MuiTooltip from '@mui/material/Tooltip'
import { useState } from 'react'

import { DeviceOS, useDeviceOS } from '@/hooks/use-device-os'
import COLORS from '@/utils/colors'

/**
 *
 * @param props
 */
export function Tooltip (props) {
  const Tag = props.component ?? 'span'
  const { deviceOS } = useDeviceOS()

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const orangeTooltip = {
    sx: {
      bgcolor: COLORS['Quality_scale-Fair'],
      fontSize: 16,
      padding: '8px 12px',
      whiteSpace: 'pre-wrap',
      '& .MuiTooltip-arrow': {
        color: COLORS['Quality_scale-Fair']
      }
    }
  }

  if (props.disabled) {
    return props.children
  }

  if (deviceOS === DeviceOS.OTHER) {
    return (
      <MuiTooltip
        title={props.title}
        componentsProps={{
          tooltip: props.orange && orangeTooltip
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14]
                }
              }
            ]
          }
        }}
        arrow
      >
        <Tag className={props.className}>{props.children}</Tag>
      </MuiTooltip>
    )
  } else {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <MuiTooltip
          title={props.title}
          PopperProps={{
            disablePortal: true
          }}
          componentsProps={{
            tooltip: props.orange ? orangeTooltip : { sx: { whiteSpace: 'pre-wrap' } }
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14]
                  }
                }
              ]
            }
          }}
          onClose={handleTooltipClose}
          open={open}
          arrow
        >
          <Tag className={props.className} style={{ display: 'flex' }} onClick={handleTooltipOpen}>{props.children}</Tag>
        </MuiTooltip>
      </ClickAwayListener>

    )
  }
}
