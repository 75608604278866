import { styled } from '@mui/material/styles'

const Container = styled('footer')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#636B74',
  padding: '40px 64px',
  margin: 0,
  position: 'relative',
  zIndex: 999,

  '& .leftSection': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .title': {
      color: 'white',
      marginTop: '24px',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '142%'
    },
    '& .download': {
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '16px',
      '& .qrCode': {
        borderRadius: '8px',
        width: '128px',
        height: '128px',
        overflow: 'hidden',
        '& svg': {
          width: '100%',
          height: '100%'
        }
      },
      '& .appDownloadLinks': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        '& .appDownloadLink': {
          maxHeight: '60px',
          cursor: 'pointer'
        }
      }
    },
    '& .scan': {
      marginTop: '24px',
      position: 'relative',
      '& .arrow': {
        position: 'absolute',
        top: '-16px',
        left: '-11px',
        height: '35px'
      },
      '& .text': {
        position: 'relative',
        left: '24px',
        color: '#FFF',
        fontFamily: 'Nanum Pen Script',
        fontSize: '31px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '142%'
      }
    }
  },
  '& .rightSection': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& .linkSections': {
      display: 'flex',
      gap: '56px',
      '& .linkSection': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        gap: '16px',
        '& .title': {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '150%',
          color: 'white'
        },
        '& .links': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '8px',
          '& .link': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            paddingTop: 0,
            '& a, span': {
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '142%',
              textDecoration: 'none',
              color: 'white',
              textAlign: 'right',
              cursor: 'pointer'
            }
          }
        }
      }
    },
    '& .bottom': {
      color: 'white',
      textAlign: 'right',
      fontFamily: 'Inter',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '133%'
    }
  },
  '&.mobile': {
    flexWrap: 'wrap',
    padding: '32px 30px',
    gap: '32px',
    width: 'auto',
    '& .download, .scan': {
      display: 'none'
    },
    '& .rightSection': {
      width: '100%',
      alignItems: 'flex-start',
      '& .linkSections': {
        flexDirection: 'column',
        gap: '32px',
        '& .linkSection': {
          alignItems: 'flex-start',
          '& .links': {
            alignItems: 'flex-start',
            '& .link': {
              flexFlow: 'row-reverse'
            }
          }
        }
      },
      '& .bottom': {
        width: '100%',
        borderTop: '1px solid #9FA6AD',
        textAlign: 'left',
        marginTop: '32px',
        paddingTop: '32px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '142%'
      }
    }
  }

})

export default Container
