import dayjs from 'dayjs'

import { millinow } from '.'

/**
 * Format time duration in seconds to more human readable format (hours/minutes etc.)
 *
 * @exports
 * @param {object} options
 * @param {string} options.pref prefix formatted string
 * @param {string} options.suf suffix formatted string
 * @param {number} seconds duration in seconds
 * @returns {string} formatted string
 */
export function formatDuration (seconds, options = {}) {
  const { pref = '', suf = '' } = options
  const time = {
    d: Math.floor(seconds / 86400),
    h: Math.floor(seconds / 3600) % 24,
    m: Math.floor(seconds / 60) % 60,
    s: Math.floor(seconds % 60)
  }

  const out = Object.entries(time)
    .filter((val) => val[0] === 's' || val[1] !== 0) // Always show seconds
    .map((val) => val[1] + val[0])
    .join(' ')

  return `${pref}${out}${suf}`
}

/**
 * Formats milliseconds into a string representation of minutes and seconds.
 * @param {number} ms - The number of milliseconds to format.
 * @returns {string} A string representing the formatted minutes and seconds in the format "mm:ss".
 */
export function formatMs (ms) {
  const seconds = Math.floor((ms / 1000) % 60)
  const minutes = Math.floor((ms / 1000 / 60) % 60)

  return [
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':')
}

/**
 * Get days left from now.
 * @param {number} endAt end time in epoch seconds.
 */
export function getDaysLeft (endAt) {
  const nowDt = dayjs(millinow())
  const endDt = dayjs(endAt * 1000)

  return dayjs(endDt).diff(nowDt, 'day')
}
