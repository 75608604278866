import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import VideoPlayer from './player'

import { useShowFreeTrialBanner } from '@/hooks/monetization'
import { showBanner } from '@/store/controls'
import { isProd } from '@/utils'
import { BANNER_KEYS } from '@/utils/banners'

const VideoPage = (props) => {
  const {
    children,
    playerContent,
    onRateChange,
    jumpToVideoTime,
    settings
  } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isProd) {
      dispatch(showBanner(BANNER_KEYS.DEV_ENV))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useShowFreeTrialBanner()

  return (
    <>
      <VideoPlayer onRateChange={onRateChange} jumpToVideoTime={jumpToVideoTime} settings={settings}>
        {playerContent}
      </VideoPlayer>
      {children}
    </>
  )
}

export default VideoPage
