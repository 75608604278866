import mixpanel from 'mixpanel-browser'
import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { fetchCustomUserData, reportReferralCode, useIsLoggedIn } from '@/store/auth'
import { addReceivedWebViewCommand, setReferralCode } from '@/store/controls'
import { isInAndroidWebview } from '@/utils/mobile-app-communication'

export const useReferralCode = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  const isInAndroid = isInAndroidWebview()
  const referralCodeState = useSelector(state => state.controls.referralCode)
  const isAuthReady = useSelector(state => state.auth.authIsReady)

  async function applyReferralCode () {
    const rf = JSON.parse(window.localStorage.getItem('tmp-referral-code'))
    const referralCode = referralCodeState || rf

    if (referralCode && isAuthReady) {
      // Set the referral code in store if the user is not logged in to use it if
      // user decide to login or register and report to mixpanel
      if (!isLoggedIn) {
        mixpanel.track('referral_click', { referralCode, result: 'anonymous' })
      } else {
        /**
           * When a user is logged in, we need to report that to server
           * We want to make sure referralCode passed is in lowercase
          */
        try {
          const res = await dispatch(reportReferralCode(referralCode.toLowerCase()))
          dispatch(addReceivedWebViewCommand('REFERRED'))
          if (res.result === 'success-new' || res.result === 'success-overwrote') {
            dispatch(fetchCustomUserData())
          }
          mixpanel.track('referral_click', { referralCode, result: res.result })

          // After the action is completed, we delete the referral code from the store to prevent unwanted server calls.
          window.localStorage.removeItem('tmp-referral-code')
          dispatch(setReferralCode(''))
        } catch (e) {}
      }
    }
  }

  useEffect(() => {
    applyReferralCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, referralCodeState, isAuthReady])

  useLayoutEffect(() => {
    if (!isInAndroid) {
      const rf = searchParams.get('rf')
      if (rf) {
        window.localStorage.setItem('tmp-referral-code', JSON.stringify(rf))
        setSearchParams(params => params.delete('rf'))
        dispatch(setReferralCode(rf))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return { applyReferralCode }
}
