/* Avoid throwing errors when @react-three/fiber ThreeElement properties aren't found */
/* eslint react/no-unknown-property: "off" */

import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import {
  CameraControls,
  PerspectiveCamera,
  Sphere,
  Html,
  useProgress,
  useTexture,
  useCursor
  // SoftShadows
} from '@react-three/drei'
import { Canvas, useThree, useFrame, extend } from '@react-three/fiber'
import { usePinch } from '@use-gesture/react'
import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { easing } from 'maath'
import {
  MeshLineGeometry,
  MeshLineMaterial,
  raycast
} from 'meshline'
import { Suspense, useCallback, useEffect, useState, useMemo, useRef, useContext } from 'react'
import * as THREE from 'three'
import { useIntersectionObserver } from 'usehooks-ts'

import './ShotsViewer.css'

import { Inspector } from './Inspector.jsx'
import { PickleballCourtModel } from './PickleballCourtModel.jsx'

// Vite static assets
import Deg45Icon from '@/assets/shots-viewer/icon-45.svg?react'
import BaselineDeg45Icon from '@/assets/shots-viewer/icon-baseline-45.svg?react'
import BaselineIcon from '@/assets/shots-viewer/icon-baseline.svg?react'
import FullscreenIcon from '@/assets/shots-viewer/icon-fullscreen.svg?react'
import LockIcon from '@/assets/shots-viewer/icon-lock.svg?react'
import LockOpenedIcon from '@/assets/shots-viewer/icon-lock_opened.svg?react'
import SideIcon from '@/assets/shots-viewer/icon-side.svg?react'
import TopIcon from '@/assets/shots-viewer/icon-top.svg?react'
import playerIcon1Url from '@/assets/shots-viewer/player-icon-1.png'
import playerIcon2Url from '@/assets/shots-viewer/player-icon-2.png'
import playerIcon3Url from '@/assets/shots-viewer/player-icon-3.png'
import playerIcon4Url from '@/assets/shots-viewer/player-icon-4.png'
import useKeypress from '@/hooks/use-keypress'
import { APIContext } from '@/utils/api'
import * as bezierSpline from '@/utils/svg.js'

extend({ MeshLineGeometry, MeshLineMaterial })

/**
 * @typedef {Object} CameraState persistable/restorable camera state
 * @property {array} position the position of the camera
 * @property {array} target the target to look at
 */

/**
* @typedef {Object} WorldXYZ
* @property {number} x
* @property {number} y
* @property {number} z
*/

/**
* @typedef {Object} TimeAndPlace describes the ball’s position at some moment in time
* @property {WorldXYZ} location
* @property {number} ms the number of milliseconds elapsed since the beginning of the game (video)
*/

/**
* @typedef {Object} Shot
* @property {string} color hexadecimal value of color with no prefix (e.g.: B4E100)
* @property {TimeAndPlace} start
* @property {WorldXYZ} peak
* @property {TimeAndPlace} end
*/

/**
 * ShotsViewer Component
 *
 * @param {object} props
 * @param {CameraState} [props.camera] camera state provided by parent
 * @param {(cameraState) => void|null} props.onCameraChanged function to call when camera state under the control of the component are changed
 * @param {Shot[]} props.shots array of Shot objects to render
 * @param {number} playerId the id of the player (1…4)
 * @returns {React.ReactElement}
 */

function LoaderR3f ({ store }) {
  const { progress, loaded } = useProgress()
  if (loaded) {
    store.send({ type: 'ready' })
  }
  return <Html center>{Math.round(progress)} % loaded</Html>
}

function meshlineRaycast (raycaster, intersects) {
  raycaster.params.Line.threshold = 1 / 16 // can adjust this value to fine-tune hit area around the line
  raycast.call(this, raycaster, intersects)
}

function getLineSegment ([start, peak, end]) {
  return [start, start, end, end]
}

function getSplineSegments (points) {
  const bezierPoints = bezierSpline.combinePoints(
    points,
    bezierSpline.getControlPoints(points)
  )

  return bezierSpline.getSegments(bezierPoints)
}

function getSegmentsForCubicBezierLines (shot) {
  const points = [
    [shot.start.location.x, shot.start.location.z, shot.start.location.y],
    [shot.peak.x, shot.peak.z, shot.peak.y],
    [shot.end.location.x, shot.end.location.z, shot.end.location.y]
  ]
  // if peak and end are the same position,
  // use a straight line,
  // otherwise use a spline
  return points[1][0] === points[2][0] &&
         points[1][1] === points[2][1] &&
         points[1][2] === points[2][2]
    ? [getLineSegment(points)]
    : getSplineSegments(points)
}
function TrajectoryLine ({ shot, selection, ...props }) {
  const [hover, setHover] = useState(false)
  useCursor(hover)

  const selected = selection && selection === shot

  const color = shot.color
  const opacity = hover || (!selection || selected) ? 1 : 0.5
  const lineWidth = selected || hover ? 9 / 16 : 3 / 16

  const points = useMemo(() => {
    const segments = getSegmentsForCubicBezierLines(shot).map(segment => segment.map(([x, y, z]) => new THREE.Vector3(x, y, z)))
    const curvePath = new THREE.CurvePath()
    curvePath.curves = segments.map(([start, midA, midB, end]) => new THREE.CubicBezierCurve3(start, midA, midB, end))
    return curvePath.getPoints(segments.length * 12)
  }, [shot])

  const { invalidate } = useThree()

  const meshRef = useRef()
  const sphereRef = useRef()

  // animation
  useFrame((state, dt) => {
    easing.damp(meshRef.current.material, 'lineWidth', lineWidth, 0.05, dt)
    easing.dampC(meshRef.current.material.color, color, 0.15, dt)
    easing.damp(meshRef.current.material, 'opacity', opacity, 0.1, dt)
    easing.dampC(sphereRef.current.material.color, color, 0.15, dt)
    easing.damp(sphereRef.current.material, 'opacity', opacity, 0.1, dt)
    // force update during the animation, as we are usually in `demand` mode
    if (
      !meshRef.current.material.lineWidth !== lineWidth ||
      !meshRef.current.material.color.equals(new THREE.Color(color)) ||
      !meshRef.current.material.opacity === opacity ||
      !sphereRef.current.material.color.equals(new THREE.Color(color)) ||
      !sphereRef.current.material.opacity === opacity
    ) {
      invalidate()
    }
  })

  return (
    <group
      onPointerOver={(event) => { event.stopPropagation(); setHover(true) }}
      onPointerOut={() => setHover(false)}
      {...props}
    >
      {/* curve */}
      <mesh ref={meshRef} raycast={meshlineRaycast}>
        <meshLineGeometry points={points} />
        <meshLineMaterial color='#999' transparent />
      </mesh>

      {/* ball */}
      <Sphere
        ref={sphereRef}
        position={[shot.end.location.x, shot.end.location.z, shot.end.location.y]}
        scale={2.97 / 12}
        // castShadow
      >
        <meshBasicMaterial color='#999' transparent />
      </Sphere>
    </group>
  )
}

// eslint-disable-next-line no-unused-vars
function PlayerIcon ({ playerId, playerName, ...props }) {
  const [
    playerIcon1Texture,
    playerIcon2Texture,
    playerIcon3Texture,
    playerIcon4Texture
  ] = useTexture([
    playerIcon1Url,
    playerIcon2Url,
    playerIcon3Url,
    playerIcon4Url
  ])

  const playerIconTexture =
    playerId === 1
      ? playerIcon1Texture
      : playerId === 2
        ? playerIcon2Texture
        : playerId === 3
          ? playerIcon3Texture
          : playerId === 4
            ? playerIcon4Texture
            : null

  const fontSize =
    playerId === 1 || playerId === 2
      ? 0.5
      : playerId === 3
        ? 0.45
        : playerId === 4
          ? 0.4
          : 0.4

  const initial = playerName[0]

  const context = useMemo(() => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = 512
    canvas.height = 512

    canvas.transparent = true

    return context
  }, [])

  useEffect(() => {
    const canvas = context.canvas
    if (!playerIconTexture.image) return

    const size = canvas.width * fontSize

    context.clearRect(0, 0, canvas.width, canvas.height)
    context.font = `500 ${size}px Inter`

    //
    //
    // Uncomment to draw a debug rectangle around the text
    //
    // context.fillStyle = '#f00'
    // context.fillRect((canvas.width - size) / 2, (canvas.height - size) / 2, size, size)

    context.drawImage(playerIconTexture.image, 0, 0, canvas.width, canvas.height)

    context.fillStyle = 'white'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(initial, Math.round(canvas.width / 2), Math.ceil(canvas.height / 2) + 6)
  }, [initial, context, fontSize, playerIconTexture, playerName])

  return (
    <group
      {...props}
    >
      <sprite>
        <spriteMaterial
          depthTest={false}
          depthWrite={false}
          toneMapped={false}
          transparent
        >
          <canvasTexture
            attach='map'
            image={context.canvas}
            colorSpace={THREE.SRGBColorSpace}
          />
        </spriteMaterial>
      </sprite>
    </group>
  )
}

function Scene ({
  store,
  onCameraChanged
}) {
  const { savedPresetId } = useContext(APIContext)

  const camera = useSelector(store, state => state.context.camera)
  const presets = useSelector(store, state => state.context.presets)
  const shots = useSelector(store, state => state.context.shots)
  // const playerId = useSelector(store, state => state.context.playerId)
  const fov = useSelector(store, state => state.context.fov)
  const allowTouchControls = useSelector(store, state => state.context.allowTouchControls)
  const selection = useSelector(store, state => state.context.selection)
  const updatedAt = useSelector(store, state => state.context.updatedAt)
  // const playerName = useSelector(store, state => state.context.playerName)

  const { size, invalidate, gl } = useThree()

  useEffect(() => {
    store.send({ type: 'canvas width changed', width: size.width })
  }, [store, size.width])

  /** @type {[CameraControls | null, React.Dispatch<React.SetStateAction<CameraControls | null>>]} */
  const [
    controls,
    setControls
  ] = useState()

  useEffect(() => {
    if (controls && camera) {
      const cameraPresets = camera?.position ? camera : presets[savedPresetId || 1]
      window.requestAnimationFrame(() => {
        controls.setPosition(...cameraPresets.position, true)
        if (camera.target != null) {
          controls.setTarget(...camera.target, true)
        }
      })
      invalidate()
    }
  }, [controls, camera, invalidate, presets, savedPresetId])

  useEffect(() => {
    function handler (event) {
      onCameraChanged && onCameraChanged({
        cameraState: JSON.parse(controls.toJSON())
      })
    }
    // NOTE can use either 'rest' or 'sleep'
    // see: https://github.com/yomotsu/camera-controls#events
    if (controls) {
      controls.addEventListener('rest', handler)
    }
    return () => {
      controls && controls.removeEventListener('rest', handler)
    }
  }, [store, controls, onCameraChanged])

  useEffect(() => {
    function handler (event) {
      store.send({ type: 'user changed camera' })
    }
    if (controls) {
      controls.addEventListener('control', handler)
    }
    return () => {
      controls && controls.removeEventListener('control', handler)
    }
  }, [store, controls])

  function setCameraControlsTouchAction (_controls, _enabled) {
    if (_enabled) {
      _controls.style.touchAction = 'none'
    } else {
      _controls.style.touchAction = ''
    }
  }

  const updateCameraControls = useCallback((_controls, _allowTouchControls) => {
    // via https://github.com/yomotsu/camera-controls/blob/2aa9cdf/src/CameraControls.ts#L518-L536
    const ACTION = _controls.constructor.ACTION
    if (_allowTouchControls) {
      _controls.mouseButtons.left = ACTION.ROTATE
      _controls.mouseButtons.middle = ACTION.DOLLY
      _controls.mouseButtons.right = ACTION.TRUCK
      _controls.mouseButtons.wheel = ACTION.NONE
      _controls.touches.one = ACTION.TOUCH_ROTATE
      _controls.touches.two = ACTION.TOUCH_DOLLY_TRUCK
      _controls.touches.three = ACTION.TOUCH_TRUCK
    } else {
      _controls.mouseButtons.left = ACTION.NONE
      _controls.mouseButtons.middle = ACTION.NONE
      _controls.mouseButtons.right = ACTION.NONE
      _controls.mouseButtons.wheel = ACTION.NONE
      _controls.touches.one = ACTION.NONE
      _controls.touches.two = ACTION.NONE
      _controls.touches.three = ACTION.NONE
    }

    if (_controls._domElement) {
      setCameraControlsTouchAction(_controls._domElement, _allowTouchControls)
    }
  }, [])

  // update when the ref changes
  const handleControlsRef = useCallback((_controls) => {
    if (_controls) {
      setControls(_controls)
      updateCameraControls(_controls, allowTouchControls)
    }
  }, [allowTouchControls, updateCameraControls])

  // update when allowTouchControls changes
  useEffect(() => {
    if (controls && allowTouchControls != null) {
      updateCameraControls(controls, allowTouchControls)
    }
  }, [controls, allowTouchControls, updateCameraControls])

  usePinch(state => {
    const d = state.delta[0]
    controls._dollyInternal(-d)
    invalidate()
  }, {
    target: gl.domElement
  })

  return (
    <>
      <PerspectiveCamera
        makeDefault
        fov={fov}
      />

      {/*
      <SoftShadows
        size={37.7}
        focus={0.7}
        samples={23}
        />
      */}

      <hemisphereLight intensity={3} />

      <directionalLight
        color='white'
        position={[-4, 30, -22 - 20]}
        castShadow
        shadow-camera-near={0.1}
        shadow-camera-far={100}
        shadow-camera-left={-40 - 5}
        shadow-camera-right={40 + 5}
        shadow-camera-top={-88 - 5}
        shadow-camera-bottom={88 + 5}
        shadow-mapSize={4096}
      />

      <PickleballCourtModel receiveShadow castShadow />

      <group position={[-20 / 2, 0, -44 / 2]} rotation={[0, 0, 0]}>

        {/* <PlayerIcon
          // PlayerIcon props
          playerId={playerId}
          playerName={playerName}
          // Object3D props
          position={[10, 2.5 / 2, -2]}
          scale={2.5}
        /> */}

        {shots.map((shot, i) => {
          return (
            <TrajectoryLine
              key={`shots-${updatedAt.getTime()}-trajectory-line-${i}`}
              onClick={() => store.send({ type: 'selected', selection: shot })}
              shot={shot}
              selection={selection}
            />
          )
        }
        )}
      </group>

      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} receiveShadow>
        <planeGeometry args={[40 + 5, 88 + 5]} />
        <shadowMaterial opacity={0.05} />
      </mesh>

      <CameraControls
        ref={handleControlsRef}
        makeDefault
        maxPolarAngle={Math.PI / 2}
        minDistance={20}
        maxDistance={120}
        smoothTime={0.25}
        onStart={invalidate}
      />
    </>
  )
}

function roundToPrecision (number, precision) {
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

function matchPreset (context, event) {
  if (event.camera == null) return null

  const camera = {
    position: event.camera.position.map(n => roundToPrecision(n, 3)),
    target: event.camera.target.map(n => roundToPrecision(n, 3))
  }

  for (const [id, preset] of Object.entries(context.presets)) {
    if (
      preset.position[0] === camera.position[0] &&
      preset.position[1] === camera.position[1] &&
      preset.position[2] === camera.position[2] &&

      preset.target[0] === camera.target[0] &&
      preset.target[1] === camera.target[1] &&
      preset.target[2] === camera.target[2]
    ) {
      return id
    }
  }

  return null
}

function useFullscreenChange (callback) {
  useEffect(() => {
    document.addEventListener('fullscreenchange', callback)
    document.addEventListener('webkitfullscreenchange', callback)
    return () => {
      document.removeEventListener('fullscreenchange', callback)
      document.removeEventListener('webkitfullscreenchange', callback)
    }
  }, [callback])
}

function Sidebar ({ store, onToggleFullscreen }) {
  const { setSavedPresetId, savedPresetId } = useContext(APIContext)
  const storePresetId = useSelector(store, state => state.context.presetId)
  const isFullscreen = useSelector(store, state => state.context.isFullscreen)
  const canFullscreen = useSelector(store, state => state.context.canFullscreen)

  const presetId = storePresetId || savedPresetId

  useEffect(() => {
    setSavedPresetId(presetId)
  }, [presetId, setSavedPresetId])

  return (
    <menu>
      <button
        className={presetId === 1 ? 'selected' : null}
        title='45˚ View'
        onClick={() => store.send({ type: 'camera preset changed', presetId: 1 })}
      >
        <Deg45Icon />
      </button>
      <button
        className={presetId === 2 ? 'selected' : null}
        title='Top View'
        onClick={() => store.send({ type: 'camera preset changed', presetId: 2 })}
      >
        <TopIcon />
      </button>
      <button
        className={presetId === 3 ? 'selected' : null}
        title='Sideline View'
        onClick={() => store.send({ type: 'camera preset changed', presetId: 3 })}
      >
        <SideIcon />
      </button>
      <button
        className={presetId === 4 ? 'selected' : null}
        title='Baseline View'
        onClick={() => store.send({ type: 'camera preset changed', presetId: 4 })}
      >
        <BaselineIcon />
      </button>
      <button
        className={presetId === 5 ? 'selected' : null}
        title='45˚ Baseline View'
        onClick={() => store.send({ type: 'camera preset changed', presetId: 5 })}
      >
        <BaselineDeg45Icon />
      </button>
      {
        canFullscreen &&
          <button
            className={
              [
                'btn-fullscreen',
                isFullscreen ? 'selected' : ''
              ].join(' ')
            }
            title='Toggle Full Screen'
            onClick={() => onToggleFullscreen()}
          >
            <FullscreenIcon />
          </button>
      }
    </menu>
  )
}

export default function ShotsViewer ({
  camera,
  onCameraChanged,
  onShotViewRequested,
  shots,
  playerId,
  playerName,
  playbackId
}) {
  const { savedPresetId, getPlayerName } = useContext(APIContext)
  const store = useMemo(() => createStore(
    {
      /* Note that FOV is not saved/loaded in presets or user camera data */
      fov: 30,

      camera: null,
      shots: null,
      updatedAt: new Date(),
      playerId: null,
      playerName: null,
      presets: {
        1: {
          // position: [-24, 24, -24],
          // target: [0, -9, 0]
          position: [-47.6, 18.3, -33.5],
          target: [0, 0, 0]
        },
        2: {
          position: [-1 / 1000, 56 + 6, 0],
          target: [0, 0, 0]
        },
        3: {
          position: [-67 - 6, 0, 0],
          target: [0, 0, 0]
        },
        4: {
          position: [0, 0, -48 - 6],
          target: [0, 0, 0]
        },
        5: {
          // position: [0, 18, -36],
          // target: [0, -9, 0]
          position: [0, 32, -59],
          target: [0, 0, 0]
        }
      },
      presetId: null,
      isFullscreen: false,
      canFullscreen: document.fullscreenEnabled,
      width: null,
      requestedTouchControls: false,
      allowTouchControls: false,
      selection: null,
      ready: true
    },
    {
      'shots changed': {
        shots: (context, event) => event.shots,
        updatedAt: () => new Date()
      },
      'external camera values changed': {
        camera: (context, event) => event.camera,
        presetId: (context, event) => matchPreset(context, event)
      },
      'camera preset changed': {
        camera: (context, event) => ({ ...context.presets[event.presetId] }),
        presetId: (context, event) => event.presetId
      },
      'playerId changed': {
        playerId: (context, event) => event.playerId
      },
      'playerName changed': {
        playerName: (context, event) => event.playerName
      },
      'fullscreen changed': {
        isFullscreen: (context, event) => event.isFullscreen
      },
      'user changed camera': {
        presetId: null
      },
      ready: {
        ready: true
      },
      'canvas width changed': {
        // breakpoint for desktop vs mobile
        // camera FOV is changed depending on the width of the canvas
        // see also: ShotsViewer.css
        width: (context, event) => event.width,
        fov: (context, event) => event.width >= 440
          ? 30
          : 40,
        allowTouchControls: (context, event) => event.width >= 440 || context.requestedTouchControls
      },
      'toggled touch controls': (context, event) => {
        const requestedTouchControls = !context.requestedTouchControls
        const allowTouchControls = context.width >= 440 || requestedTouchControls
        return {
          requestedTouchControls,
          allowTouchControls
        }
      },
      selected: {
        selection: (context, event) => event.selection
      }
    }
  ), [])

  const ref = useRef()

  const ready = useSelector(store, state => state.context.ready)
  const requestedTouchControls = useSelector(store, state => state.context.requestedTouchControls)

  function toggleFullScreen () {
    if (!document.fullscreenElement) {
      ref.current.requestFullscreen()
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }

  const onFullscreenChange = useCallback(() => {
    store.send({
      type: 'fullscreen changed',
      isFullscreen: !!document.fullscreenElement
    })
  }, [store])

  useFullscreenChange(onFullscreenChange)

  useEffect(() => {
    if (camera) {
      // use camera settings if provided
      store.send({ type: 'external camera values changed', camera })
    } else {
      // otherwise, initialize to preset 1
      store.send({ type: 'camera preset changed', presetId: savedPresetId })
    }
  }, [store, camera, savedPresetId])

  useEffect(() => {
    store.send({ type: 'shots changed', shots })
  }, [store, shots])

  useEffect(() => {
    store.send({ type: 'playerId changed', playerId })
  }, [store, playerId])

  useEffect(() => {
    const playerName = getPlayerName(playerId - 1)
    store.send({ type: 'playerName changed', playerName })
  }, [store, playerId, getPlayerName])

  const { isIntersecting, ref: intersectionObserverCallback } = useIntersectionObserver()

  const onClickAway = useCallback(function (event) {
    if (store.getSnapshot().context.selection) {
      event.preventDefault()
      store.send({ type: 'selected', selection: null })
    }
  }, [store])

  // If this Shots Viewer component has the user’s attention,
  // the Escape key will close the Trajectory Inspector.
  //
  // Attention" is currently defined as the pointer being over the component,
  // but could in the future be extended to include the scroll position, etc.
  //
  // Note that on devices like tablets which have an Escape key but no pointer
  // the Escape key will currently not do anything.
  const [hasAttention, setHasAttention] = useState(false)
  useEffect(() => {
    if (!ref.current) return

    const setOver = () => setHasAttention(true)
    const setOut = () => setHasAttention(false)

    ref.current.addEventListener('pointerover', setOver)
    ref.current.addEventListener('pointerout', setOut)

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('pointerover', setOver)
        ref.current.removeEventListener('pointerout', setOut)
      }
    }
  }, [ref])
  useKeypress('Escape', () => {
    if (hasAttention) {
      store.send({ type: 'selected', selection: null })
    }
  })

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        ref={node => {
          ref.current = node
          intersectionObserverCallback(node)
        }}
        className='ShotsViewer'
      >
        {
          isIntersecting &&
            <Canvas frameloop='demand' shadows>
              <Suspense fallback={<LoaderR3f store={store} />}>
                <Scene
                  store={store}
                  onCameraChanged={onCameraChanged}
                />
              </Suspense>
            </Canvas>
        }

        {ready &&
          <>
            <button
              className='ShotsViewer__lock-toggle'
              onClick={() => { store.send({ type: 'toggled touch controls' }) }}
            >
              {
                requestedTouchControls === false &&
                  <div className='ShotsViewer__lock-hint'>Unlock 3D controls</div>
              }
              {
                requestedTouchControls
                  ? <LockOpenedIcon />
                  : <LockIcon />
              }
            </button>
            <Inspector store={store} playbackId={playbackId} onShotViewRequested={onShotViewRequested} />
            <Sidebar store={store} onToggleFullscreen={toggleFullScreen} />
          </>}
      </div>
    </ClickAwayListener>
  )
}
