import { Dialog, styled } from '@mui/material'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ShotsDialog } from '@/components/dialog/shots-dialog'
import { ExperimentalIcon } from '@/components/experimental-icon'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'
import theme from '@/utils/theme'

const Container = styled('div')({
  color: COLORS['neutral-800'],
  '& .sa-progress': {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    borderRadius: theme.shape.borderRadiusSmall,
    overflow: 'hidden',
    color: '#fff',
    fontSize: 12,
    cursor: 'pointer',

    '& > div': {
      padding: '0 4px',
      display: 'flex',
      justifyContent: 'center',
      // textAlign: 'center',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
      '& > span': {
        flexGrow: 1,
        textAlign: 'center'
      }
    },
    '& .sa-in': {
      backgroundColor: COLORS['primary-500']
    },
    '& .sa-net': {
      backgroundColor: COLORS['Players/Ball-machine']
    },
    '& .sa-out': {
      backgroundColor: COLORS['Quality_scale-Poor']
    }
  },
  '& .sa-title': {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    marginBottom: 4,
    '& .sa-total': {
      marginLeft: 'auto'
    },
    '& svg': {
      color: COLORS['Quality_scale-Fair']
    }
  }
})

const getLabel = (text, size) => size >= 20 ? `${text} (${size}%)` : size >= 10 ? text : ''

export default function ShotAccuracy (props) {
  const { stats, playerIdx, playerName, total } = props
  const ref = useRef(null)
  const isMobile = useMobileDetect()

  const [dialogData, setDialogData] = useState(null)
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 })
  const navigate = useNavigate()
  const { vid } = useParams()

  function createExploreUrl (typeId) {
    const types = ['net', 'out']
    // const types = ['in', 'net', 'out']
    const params = new URLSearchParams()

    // Add type parameter
    params.append('types', types[typeId])
    // Add players[0] parameter
    params.append('players[0]', playerIdx)

    // Construct the full URL
    const url = `explore?${params.toString()}`
    return url
  }

  function onRedirectToShotExplorer (level) {
    const url = createExploreUrl(level)
    scrollToTopOfMain()
    navigate(`/video/${vid}/${url}`)
  }

  function handleSingleChartClick (playerIdx, ref, title) {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()

      const buttonsForShotDialog = [
        // {
        //   color: COLORS['Quality_scale-Excellent'],
        //   label: 'In',
        //   shotCount: ((stats.in / 100) * props.total).toFixed(0),
        //   callback: onRedirectToShotExplorer
        // },
        {
          color: COLORS.pink,
          label: 'Net',
          shotCount: ((stats.net / 100) * total).toFixed(0),
          callback: onRedirectToShotExplorer
        },
        {
          color: COLORS['Quality_scale-Poor'],
          label: 'Out',
          shotCount: ((stats.out / 100) * total).toFixed(0),
          callback: onRedirectToShotExplorer
        }
      ]

      // Calculate position above the clicked element
      setDialogPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      })
      setDialogData({ buttonsForShotDialog, playerIdx, playerName, title })
    }
  }

  function onCloseDialog () {
    setDialogData(null)
  }

  const dialogDesktopStyle = {
    position: 'absolute',
    top: '50%', // Vertically center the dialog
    left: dialogPosition.left + (dialogPosition.width / 2), // Horizontally position based on clicked element
    transform: 'translateY(-50%) translateX(-50%)',
    margin: 0
  }

  return (
    <Container ref={ref}>
      <div className='sa-title'>
        <p className='body-sm bold'>Shot Accuracy</p>
        <ExperimentalIcon />
        <p className='body-sm sa-total'>Total Shots: {total}</p>
      </div>
      <div className='sa-progress' onClick={() => handleSingleChartClick(playerIdx, ref, 'Shot Accuracy')}>
        <div className='sa-in' style={{ width: `${stats.in}%` }}>
          <Tooltip disabled={isMobile} title={`In (${stats.in}%)`}>
            {getLabel('In', stats.in)}
          </Tooltip>
        </div>
        <div className='sa-net' style={{ width: `${stats.net}%` }}>
          <Tooltip disabled={isMobile} title={`Net (${stats.net}%)`}>
            {getLabel('Net', stats.net)}
          </Tooltip>
        </div>
        <div className='sa-out' style={{ width: `${stats.out}%` }}>
          <Tooltip disabled={isMobile} title={`Out (${stats.out}%)`}>
            {getLabel('Out', stats.out)}
          </Tooltip>
        </div>
      </div>
      <Dialog
        onBackdropClick={onCloseDialog}
        open={Boolean(dialogData)}
        PaperProps={{
          style: !isMobile ? dialogDesktopStyle : {}
        }}
      >
        <ShotsDialog buttons={dialogData?.buttonsForShotDialog} onClose={onCloseDialog} playerIdx={dialogData?.playerIdx} playerName={dialogData?.playerName} title={dialogData?.title} />
      </Dialog>
    </Container>
  )
}
