import { styled } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import HelpIcon from '@/assets/help.svg?react'
import { Avatar } from '@/components/avatar'
import { CourtKitchen } from '@/components/court-kitchen'
import { PlayerImage } from '@/components/player-image'
import { TagPlayer } from '@/components/tag-player'
import { Tooltip } from '@/components/tooltip'
import { APIContext } from '@/utils/api'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import theme from '@/utils/theme'

export default function AllPlayersCourtKitchenChart ({ title, tooltip, className, isReturning }) {
  const { insights, aiEngineVersion, getPlayerName } = useContext(APIContext)
  const { vid } = useParams()
  const kitchenArrivals = useMemo(() => {
    const serves = []
    const returns = []

    /* eslint-disable camelcase */
    insights?.player_data.forEach(({ role_data }) => {
      const calcWhenServing =
            role_data.serving.oneself.kitchen_arrival && role_data.serving.oneself.total
              ? (role_data.serving.oneself.kitchen_arrival + role_data.serving.partner.kitchen_arrival) /
                (role_data.serving.oneself.total + role_data.serving.partner.total)
              : 0
      const calcWhenReceiving =
            role_data.receiving.oneself.kitchen_arrival && role_data.receiving.oneself.total
              ? (role_data.receiving.oneself.kitchen_arrival + role_data.receiving.partner.kitchen_arrival) /
                (role_data.receiving.oneself.total + role_data.receiving.partner.total)
              : 0
      // [number of serving rallies where the player made it to the kitchen] / [number of total rallies where this player's team served] * 100%
      serves.push(round(calcWhenServing * 100))
      returns.push(round(calcWhenReceiving * 100))
    })
    return [serves, returns]
    /* eslint-enable camelcase */
  }, [insights?.player_data])
  return (
    <Container className={className}>
      <div className='label'>
        <Tooltip title={tooltip}>
          <HelpIcon />
        </Tooltip>
        <p className='title-lg'>{title}</p>
      </div>
      <div className='content'>
        <div className='team team-1'>
          <div className='player'>
            <PlayerImage width={75} height={75} playerIdx={0} numberOfImages={1} imageWidth={100} hasBorder />
            <Avatar width='35' initials={getPlayerName(0)} playerId={0} className='avatar' />
            <TagPlayer vid={vid} playerIdx={0} playerName={getPlayerName(0)} aiEngineVersion={aiEngineVersion} truncate />
          </div>
          <div className='player'>
            <PlayerImage width={75} height={75} playerIdx={1} numberOfImages={1} imageWidth={100} hasBorder />
            <Avatar width='35' initials={getPlayerName(1)} playerId={1} className='avatar' />
            <TagPlayer vid={vid} playerIdx={1} playerName={getPlayerName(1)} aiEngineVersion={aiEngineVersion} truncate />
          </div>
        </div>
        <CourtKitchen values={kitchenArrivals[isReturning ? 1 : 0]} />
        <div className='team team-2'>
          <div className='player'>
            <PlayerImage width={75} height={75} playerIdx={2} numberOfImages={1} imageWidth={100} hasBorder />
            <Avatar width='35' initials={getPlayerName(2)} playerId={2} className='avatar' />
            <TagPlayer vid={vid} playerIdx={2} playerName={getPlayerName(2)} aiEngineVersion={aiEngineVersion} truncate />
          </div>
          <div className='player'>
            <PlayerImage width={75} height={75} playerIdx={3} numberOfImages={1} imageWidth={100} hasBorder />
            <Avatar width='35' initials={getPlayerName(3)} playerId={3} className='avatar' />
            <TagPlayer vid={vid} playerIdx={3} playerName={getPlayerName(3)} aiEngineVersion={aiEngineVersion} truncate />
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  gap: theme.spacing(2),
  '& .label': {
    ...row,
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  '& .content': {
    ...row,
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-around',
    '& .court-kitchen': {
      maxWidth: '40%'
    }
  },
  '& .team': {
    ...column,
    gap: theme.spacing(1),
    width: '30%',
    maxWidth: '30%',
    '&.team-1 .avatar': {
      marginLeft: -22
    },
    '&.team-2 .avatar': {
      marginRight: -22
    },
    '&.team-2 .player': {
      flexDirection: 'row-reverse'
    }
  },
  '& .player': {
    ...row,
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  [theme.breakpoints.down('lg')]: {
    '& .content': {
      ...column,
      '& .court-kitchen': {
        maxWidth: '80%',
        width: '80%'
      }
    },
    '& .team': {
      maxWidth: '80%',
      width: '80%',
      '&.team-1 ': {
        alignSelf: 'flex-start',
        marginLeft: '10%'
      },
      '&.team-2 ': {
        alignSelf: 'flex-end',
        marginRight: '10%'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .content': {
      '& .court-kitchen': {
        maxWidth: '100%',
        width: '100%'
      }
    },
    '& .team': {
      maxWidth: '100%',
      width: '100%',
      '&.team-1 ': {
        alignSelf: 'flex-start',
        marginLeft: '0'
      },
      '&.team-2 ': {
        alignSelf: 'flex-end',
        marginRight: '0'
      }
    }
  }
})
