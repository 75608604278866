import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

// import COLORS from '@/utils/colors'

/**
 * Avatar from Initials
 *
 * @exports
 * @param props {object} { round, width, initials }
 * @returns {React.ReactElement}
 */
export const Avatar = styled('div', {
  shouldForwardProp: (prop) => !['round', 'width', 'initials', 'playerId'].includes(prop)
})(({ round, width, initials = 'P', playerId }) => {
  const WIDTH = Number(width || 35)
  const firstLetter = initials.slice(0, 1)
  let backgroundColor = ''

  switch (Number(playerId)) {
    case 0:
      backgroundColor = COLORS.player1
      break
    case 1:
      backgroundColor = COLORS.player2
      break
    case 2:
      backgroundColor = COLORS.player3
      break
    case 3:
      backgroundColor = COLORS.player4
      break
    default:
      backgroundColor = COLORS.player1
  }

  const isRounded = playerId < 2 || round

  return {
    position: 'relative',
    height: `${WIDTH}px`,
    width: `${WIDTH}px`,
    minWidth: `${WIDTH}px`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,

    '&:before': {
      display: 'block',
      position: 'absolute',
      right: 0,
      content: '""',
      width: '100%',
      height: '100%',
      backgroundColor,
      zIndex: 0,
      ...(isRounded
        ? {
            borderRadius: '100%'
          }
        : {
            borderRadius: 2.6,
            transform: 'rotate(45deg)',
            transformOrigin: 'center',
            height: WIDTH - WIDTH * 0.14285714285714285,
            width: WIDTH - WIDTH * 0.14285714285714285
          })
    },
    '&:after': {
      content: `"${firstLetter}"`,
      color: '#fff',
      zIndex: 1,
      fontSize: `${WIDTH / 2}px`,
      fontWeight: 500,
      lineHeight: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      ...(isRounded
        ? {
            left: '50%'
          }
        : {
            left: '56%'
          })
    }
  }
})
