import { Skeleton } from '@mui/material'
import React from 'react'

import COLORS from '@/utils/colors'

export function SkeletonComponent ({ rootClassName, height }) {
  return (
    <Skeleton animation='wave' variant='rectangular' classes={{ root: rootClassName }} sx={{ backgroundColor: `${COLORS['neutral-200']} !important`, borderRadius: 2 }} component='div' height={height || '100%'} width='100%' />
  )
}

export function SkeletonWrapper (props) {
  const { isLoading, children } = props
  if (isLoading) {
    return (
      <SkeletonComponent rootClassName='skeleton-component' {...props} />
    )
  }

  return children
}
