import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Menu, MenuItem, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import { Divider } from '@/components/divider'
import { PlayerImage } from '@/components/player-image'
import { TagPlayer } from '@/components/tag-player'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { showBanner } from '@/store/controls'
import { setVideoField, useMayEditVideo } from '@/store/video'
import { BANNER_KEYS } from '@/utils/banners'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

const scoreOptions = [...Array(26).keys()]
const getInitial = (playerName) => playerName?.slice(0, 1) || 'P'

export default function GameOverview (props) {
  const { vid, aiEngineVersion, video, insights } = props
  const isMobile = useMobileDetect()
  const mayEdit = useMayEditVideo(vid)
  const dispatch = useDispatch()

  const [scoreAnchorEl, setScoreAnchorElInState] = useState(null)

  const isScoreAnchorEl = Boolean(scoreAnchorEl)
  const { userData } = video

  const setScoreAnchorEl = useCallback(newValue => {
    if (!newValue || mayEdit) {
      setScoreAnchorElInState(newValue)
    } else {
      dispatch(showBanner(BANNER_KEYS.NOT_YOUR_VIDEO))
    }
  }, [dispatch, mayEdit])

  if (!insights) {
    return 'Loading...'
  }

  const { game_data: gameData } = insights
  const gameOutcome = userData.teamOutcomes ?? gameData.game_outcome

  const handleScore = (val) => {
    closeScoreMenu()
    const teamIndex = scoreAnchorEl?.id === 'team1' ? 0 : scoreAnchorEl?.id === 'team2' ? 1 : null
    if (teamIndex === null) return
    let newScores = [...gameOutcome]
    if (val === 'Clear') {
      newScores = []
    } else {
      const smallVal = String(val).toLowerCase()
      if (['won', 'lost'].includes(smallVal)) {
        newScores[teamIndex] = smallVal
        newScores[teamIndex === 0 ? 1 : 0] = smallVal === 'won' ? 'lost' : 'won'
      } else {
        newScores[teamIndex] = Number(smallVal)
      }
    }
    dispatch(setVideoField(vid, 'teamOutcomes', newScores))
  }

  const closeScoreMenu = () => {
    setScoreAnchorEl(null)
  }

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='team-container'>
        <div className='column'>
          <PlayerImage className='img player1' width={75} height={75} playerIdx={0}>
            <Avatar width='35' initials={getInitial(userData.players[0]?.name)} playerId={0} />
          </PlayerImage>
          <PlayerImage className='img player2' width={75} height={75} playerIdx={1}>
            <Avatar width='35' initials={getInitial(userData.players[1]?.name)} playerId={1} />
          </PlayerImage>
        </div>
        <div className='column'>
          <div className='player grow ml26 mh72'>
            <TagPlayer vid={vid} playerIdx={0} playerName={userData.players[0]?.name} aiEngineVersion={aiEngineVersion} truncate />
          </div>
          <div className='team grow ml26'>Team A</div>
          <div className='player grow ml26 mh72'>
            <TagPlayer vid={vid} playerIdx={1} playerName={userData.players[1]?.name} aiEngineVersion={aiEngineVersion} truncate />
          </div>
        </div>
      </div>
      <div className='column result'>
        <span>Score</span>
        <div className='row items-center'>
          <div>
            <Button
              id='team1'
              variant='outlined'
              color='midnight'
              className={cls(gameOutcome[0] === 'won' && 'won', gameOutcome[0] === 'lost' && 'lost')}
              sx={buttonStyle}
              onClick={(event) => {
                setScoreAnchorEl(event.currentTarget)
              }}
            >
              {gameOutcome[0]}
              <ArrowDropDownIcon />
            </Button>
          </div>
          <div className='remove-icon' />
          <div>
            <Button
              id='team2'
              variant='outlined'
              color='midnight'
              className={cls(gameOutcome[1] === 'won' && 'won', gameOutcome[1] === 'lost' && 'lost')}
              size='large'
              sx={buttonStyle}
              onClick={(event) => {
                setScoreAnchorEl(event.currentTarget)
              }}
            >
              {gameOutcome[1]}
              <ArrowDropDownIcon />
            </Button>
          </div>
        </div>
        <Menu
          id='score-button'
          anchorEl={scoreAnchorEl}
          open={isScoreAnchorEl && mayEdit}
          onClose={closeScoreMenu}
          sx={{ '& li': { justifyContent: 'center' } }}
          MenuListProps={{ 'aria-labelledby': 'score-button' }}
          slotProps={{
            paper: {
              style: {
                maxHeight: 440
              }
            }
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
        >
          <MenuItem onClick={() => handleScore('Clear')}>-</MenuItem>
          <MenuItem
            onClick={() => handleScore('won')}
            sx={{ color: scoreColors.Won }}
            selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === 'won'}
          >
            Won
          </MenuItem>
          <MenuItem
            onClick={() => handleScore('Lost')}
            sx={{ color: scoreColors.Lost }}
            selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === 'Lost'}
          >
            Lost
          </MenuItem>
          <Divider />
          {scoreOptions.map((val) => (
            <MenuItem
              key={val}
              onClick={() => handleScore(val)}
              selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === val}
            >
              {val}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className='team-container align-right'>
        <div className='column team2'>
          <div className='player grow m40 tar mh72'>
            <TagPlayer vid={vid} playerIdx={2} playerName={userData.players[2]?.name} aiEngineVersion={aiEngineVersion} truncate />
          </div>
          <div className='team grow m40 tar'>Team B</div>
          <div className='player grow m40 tar mh72'>
            <TagPlayer vid={vid} playerIdx={3} playerName={userData.players[3]?.name} aiEngineVersion={aiEngineVersion} truncate />
          </div>
        </div>
        <div className='column team2-icons'>
          <PlayerImage className='img player3' width={75} height={75} playerIdx={2}>
            <Avatar width='35' initials={getInitial(userData.players[2]?.name)} playerId={2} />
          </PlayerImage>
          <PlayerImage className='img player4' width={75} height={75} playerIdx={3}>
            <Avatar width='35' initials={getInitial(userData.players[3]?.name)} playerId={3} />
          </PlayerImage>
        </div>
      </div>
    </Container>
  )
}

const scoreColors = { won: COLORS['primary-500'], lost: '#f00' }

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(16, 1fr)',
  color: COLORS['00-on-surface-high-emphasis'],
  gap: theme.spacing(1),

  '& > div': {

  },
  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .team-container': {
    gridColumn: 'span 6',
    ...row,
    '& .column': {
      maxWidth: 'calc(100% - 75px)'
    }
  },
  '& .img': {
    '&:first-of-type': {
      marginBottom: 8
    },
    // Position avatar initials relative to the image rectangle
    '&.player1 div': { top: 14 },
    '&.player2 div': { bottom: 14 },
    '&.player3 div': { top: 14 },
    '&.player4 div': { bottom: 14 },
    '&.player1, &.player2': {
      '& div': {
        right: -24
      }
    },
    '&.player3, &.player4': {
      '& div': {
        left: -28
      }
    },
    '& div': {
      // Position the avatar absolutely in the container
      position: 'absolute'
    }
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .player': {
    fontSize: 20,
    fontWeight: 600,
    alignItems: 'center',
    ...row,
    '& button span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '120px',
      width: 'unset !important'
    }
  },
  '& .team2': {
    overflow: 'hidden',
    '.player': {
      justifyContent: 'flex-end'
    }
  },
  '& .team2-icons': {
    overflow: 'unset !important'
  },
  '& .team': {
    fontSize: 14,
    fontWeight: 300,
    justifyContent: 'center',
    maxHeight: 14,
    ...column
  },
  '& .result': {
    gridColumn: 'auto / span 4',
    alignItems: 'center',
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    textAlign: 'center',
    flexBasis: 0,
    margin: '0 4px',

    '& > span': {
      marginBottom: 5
    }
  },
  '& .items-center': {
    alignItems: 'center'
  },
  '& .remove-icon': {
    backgroundColor: COLORS.black,
    height: 3,
    width: 12,
    margin: '0 12px'
  },
  '& .ml26': {
    marginLeft: 26
  },
  '& .mh72': {
    maxHeight: 72
  },
  '& .m40': {
    marginRight: 40
  },
  '& .tar': {
    textAlign: 'right'
  },
  '& .edit-ico': {
    cursor: 'pointer',
    fontSize: 18,
    color: '#BDBDBD',
    margin: '0 8px'
  },
  '& button.won': {
    color: scoreColors.won,
    textTransform: 'capitalize'
  },
  '& button.lost': {
    color: scoreColors.lost,
    textTransform: 'capitalize'
  },
  // Inline edit size fix
  '& .editing input': {
    padding: '2px 4px'
  },
  [theme.breakpoints.up('md')]: {
    '& .align-right': {
      justifyContent: 'flex-end',
      '& span': {
        justifyContent: 'flex-end'
      }
    }
  },
  // Mobile style overrides
  [theme.breakpoints.down('md')]: {
    '& .img.player3, & .img.player4': {
      '& div': {
        right: -28,
        left: 'auto'
      }
    },
    '& .team-container, .result': {
      gridColumn: 'span 16'
    },
    '& .result': {
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px 0',
      width: '100%',
      '& span': {
        marginRight: 17
      }
    },
    '& .team2': {
      order: 2,
      '& .player': {
        justifyContent: 'flex-start'
      },
      '& .tar': {
        textAlign: 'left'
      }
    },
    '& .m40': {
      marginRight: 0,
      marginLeft: 37
    },
    '& .ml26': {
      marginLeft: 28
    }
  }
})

const buttonStyle = {
  ...row,
  backgroundColor: COLORS['neutral-050'],
  width: '100%',
  alignSelf: 'center',
  padding: 0,
  borderRadius: 4,
  minWidth: 56,
  minHeight: 56,
  '& svg': {
    marginLeft: 0,
    color: '#323232',
    fontSize: 14,
    position: 'absolute',
    bottom: 4,
    left: '50%',
    transform: 'translateX(-50%)'
  }
}
