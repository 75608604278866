import { styled } from '@mui/material/styles'
import Plot from 'react-plotly.js'

import { useNthShotsForPlayerWithContext } from '../player-stats/stats-sections/selected-shots-viewer'

import useMobileDetect from '@/hooks/use-mobile-detect'
import cl from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import theme from '@/utils/theme'

const serveTypes = {
  Deep: COLORS['Quality_scale-Excellent'],
  Medium: COLORS.player3,
  Shallow: COLORS['Quality_scale-Fair']
}
const labels = Object.keys(serveTypes)
const colors = Object.values(serveTypes)

// cspell:disable
const genericAnnotation = {
  font: {
    size: 16,
    family: 'Inter, Helvetica, Arial, sans-serif'
  },
  showarrow: false,
  x: 0.5,
  y: 0.5
}

const genericLayout = {
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 0
  },
  font: {
    // size: 16,
    weight: 500,
    family: 'Inter, Helvetica, Arial, sans-serif'
  },
  showlegend: false
}

const genericData = {
  labels,
  marker: {
    colors
  },
  textposition: 'inside',
  texttemplate: '%{percent:.2p}',
  hoverinfo: 'none',
  type: 'pie'
}
// cspell:enable

const Container = styled('div')({
  ...column,
  gap: theme.spacing(1),
  '& ._pies': {
    ...column,
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  },

  '& ._legend': {
    display: 'flex',
    gap: theme.spacing(4),
    justifyContent: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: 4
    },
    ...labels.reduce((acc, label) => {
      acc[`& .${label}:before`] = {
        content: '""',
        display: 'block',
        backgroundColor: serveTypes[label],
        width: 23,
        height: 12,
        borderRadius: 2
      }
      return acc
    }, {})
  },
  '& .slicetext': {
    fill: COLORS.black
  },
  // Force bold text on slice labels
  '& .main-svg g.slice text': {
    fontWeight: 600
  },
  // Mobile overrides
  [theme.breakpoints.down('lg')]: {
    '& ._pies': {
      marginTop: 20
    }
  },
  [theme.breakpoints.down('md')]: {
    '& ._pies': {
      ...row,
      gap: theme.spacing(2),
      paddingLeft: 0,
      alignItems: 'center',
      marginTop: 50
    },
    '& ._legend': {
      ...row,
      marginTop: 20,
      gap: theme.spacing(2)
    }
  }
})

function Pie (props) {
  const { values, name, hole, width, height } = props

  return (
    <Plot
      layout={{
        ...genericLayout,
        annotations: [{ ...genericAnnotation, text: `<b>${name.replace(' ', '<br>')}</b>` }],
        width,
        height
      }}
      data={[{ ...genericData, values, name, hole }]}
      config={{ displayModeBar: false, responsive: true }}
    />
  )
}

const typesToArray = (object) => {
  const { deep = 0, mid = 0, short = 0 } = object
  return [round(deep), round(mid), round(short)]
}

function useDepthsForShots (insights, playerIdx, shotNum) {
  const shotTypesToInclude = new Set(['drive', 'drop', 'lob'])
  const ret = { deep: 0, mid: 0, short: 0, net: 0 }
  const shots = useNthShotsForPlayerWithContext(insights, playerIdx, shotTypesToInclude, shotNum)
  shots.forEach(obj => {
    const { shot } = obj
    const endZone = shot.resulting_ball_movement?.trajectory?.end?.zone
    if (endZone) {
      const zoneToUpdate =
        Object.prototype.hasOwnProperty.call(ret, endZone)
          ? endZone
          : (endZone === 'kitchen' ? 'short' : undefined)
      if (zoneToUpdate) {
        ret[zoneToUpdate]++
      }
    }
  })
  return ret
}

/**
 *
 * @param props
 */
export function ServesReturns (props) {
  const isMobile = useMobileDetect()
  const { playerIdx, insights } = props
  const serves = useDepthsForShots(insights, playerIdx, 0)
  const returns = useDepthsForShots(insights, playerIdx, 1)

  return (
    <Container className={cl(isMobile && 'mobile')}>
      <div className='_pies'>
        <Pie values={typesToArray(serves)} hole={0.55} width={isMobile ? 180 : 210} height={isMobile ? 180 : 210} name='Serve Depth' />
        <Pie values={typesToArray(returns)} hole={0.55} width={isMobile ? 150 : 160} height={isMobile ? 150 : 160} name='Return Depth' />
      </div>
      <div className='_legend'>
        {labels.map((label) => (
          <div key={label} className={label}>
            <p className='body-xs'>{label}</p>
          </div>
        ))}
      </div>
    </Container>
  )
}
