import { styled } from '@mui/material'
import React from 'react'

import COLORS from '@/utils/colors'
import { column } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function HomePageLayout ({ children }) {
  return (
    <Container>{children}</Container>
  )
}

const Container = styled('div')({
  ...column,
  gap: theme.spacing(1),
  paddingTop: theme.spacing(5),
  paddingInline: theme.spacing(2),
  maxWidth: 950 + 300,
  width: '100%',
  '& .insights-group': {
    ...column,
    width: '100%',
    gap: theme.spacing(2),
    justifyContent: 'flex-start'
  },
  '& .insights-row-grid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(16, 1fr)',
    gridAutoFlow: 'auto',
    gap: theme.spacing(2),
    '& > div': {
      backgroundColor: COLORS.white,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadiusSmall,
      overflow: 'hidden',
      height: 'fit-content',
      '&.without-padding': {
        padding: 0
      },
      '&.transparent': {
        backgroundColor: 'transparent'
      }
    },
    '& .full': {
      gridColumn: 'span 16'
    },
    '& .half': {
      gridColumn: 'span 8'
    },
    '& .quarter': {
      gridColumn: 'span 4'
    },
    '& .video-sidebar-column': {
      gridColumn: 'span 4',
      '&.shot-explorer-aside': {
        height: '90vh'
      }
    },
    '& .video-column': {
      gridColumn: 'span 12'
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& .insights-row-grid': {
      '& .video-sidebar-column': {
        gridColumn: 'span 16',
        order: 0,
        '&.shot-explorer-aside': {
          height: '50vh !important'
        }
      },
      '& .video-column': {
        gridColumn: 'span 16'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
    '& .insights-row-grid': {
      gridTemplateColumns: 'repeat(8, 1fr)',
      '& .full': {
        gridColumn: 'span 8'
      },
      '& .half': {
        gridColumn: 'span 8'
      },
      '& .quarter': {
        gridColumn: 'span 4'
      },
      '& .video-sidebar-column': {
        gridColumn: 'span 8'
      },
      '& .video-column': {
        gridColumn: 'span 8'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .insights-row-grid': {
      gridTemplateColumns: '1fr',
      '& .half': {
        gridColumn: 'span 1'
      },
      '& .quarter': {
        gridColumn: 'span 1'
      }
    }
  }
})
