import { QRCodeCanvas } from 'qrcode.react'
import { forwardRef, useRef, useImperativeHandle } from 'react'

import imgsrc from '@/assets/icons/pbv-eye.svg'
import { downloadOrOpenLink } from '@/utils/download'

const QRCodeComponent = forwardRef(({ value, size = 295, imageSettings, downloadedFileName = 'pb-vision' }, ref) => {
  const qrRef = useRef()

  // Expose the downloadQRCode method to the parent via the ref
  useImperativeHandle(ref, () => ({
    downloadQRCode: () => {
      const canvas = qrRef.current
      const imageUrl = canvas.toDataURL('image/png')

      downloadOrOpenLink(imageUrl, downloadedFileName, true)
    }
  }))

  return (
    <QRCodeCanvas
      ref={qrRef}
      value={value}
      size={size}
      marginSize={1}
      imageSettings={{ src: imgsrc, width: 50, height: 71, excavate: true, ...imageSettings }}
    />
  )
})

QRCodeComponent.displayName = 'QRCodeComponent'

export default QRCodeComponent
