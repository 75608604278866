import { styled } from '@mui/material'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePlayersShotQuality } from '../../hooks/use-players-shot-quality'

import { Avatar } from '@/components/avatar'
import { PlayerImage } from '@/components/player-image'
import CourtCoverageGraph from '@/components/player-stats/stats-sections/court-coverage-graph'
import SelectedShotsViewer from '@/components/player-stats/stats-sections/selected-shots-viewer'
import ServesReturnGraph from '@/components/player-stats/stats-sections/serves-return-depth'
import ServesReturnsViewer from '@/components/player-stats/stats-sections/serves-returns'
import ShotAccuracy from '@/components/player-stats/stats-sections/shot-accuracy'
import ShotAverageStats from '@/components/player-stats/stats-sections/shot-average-stats'
import { useRalliesPlayersStats } from '@/components/player-stats/use-rallies-stats'
import { SkeletonWrapper } from '@/components/skeleton'
import { TagPlayer } from '@/components/tag-player'
import ProcessingProgress from '@/components/video-player/processing-progress'
import SinglePlayerPerformanceChart from '@/pages/home/components/single-player-performance-chart'
import { APIContext } from '@/utils/api'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function SinglePlayerInsightsTab () {
  const { vid, tabId } = useParams()
  const { getPlayerName, insights, aiEngineVersion, workflow } = useContext(APIContext)
  const playerStats = useRalliesPlayersStats(insights?.rallies)
  const { getShotQualityForAllShotsPerPlayer } = usePlayersShotQuality()
  const isWorkflowDone = Boolean(workflow?.epochFinished)

  const [shotNumberSelection, setShotNumberSelection] = useState(999)
  const onShotNumberSelectionChange = useCallback(function onShotNumberSelectionChange (event) {
    event.preventDefault()
    setShotNumberSelection(parseInt(event.target.value))
  }, [])

  const shotsByQualityPerPlayer = useMemo(() => {
    const qualityPerPlayerObj = getShotQualityForAllShotsPerPlayer(insights)

    return qualityPerPlayerObj
  }, [getShotQualityForAllShotsPerPlayer, insights])

  const realPlayerIdx = tabId - 1
  return (
    <Container>
      <div className='insights-group'>
        <h3>{getPlayerName(tabId - 1)}</h3>
        <div className='insights-row-grid'>
          {workflow && !isWorkflowDone && (
            <ProcessingProgress
              vid={vid}
              workflowId={workflow.workflowId}
              workflowIndex={0}
              className='full'
            />
          )}
          <SkeletonWrapper isLoading={!isWorkflowDone} height={400} rootClassName='full'>
            <div className='full shot-viewer'>
              <div className='player-info'>
                <div>
                  <PlayerImage playerIdx={realPlayerIdx} numberOfImages={4} width={300} height={130} className='player-avatar' />
                  <div className='player-name'>
                    <Avatar playerId={realPlayerIdx} initials={getPlayerName(realPlayerIdx)} />
                    <TagPlayer vid={vid} playerIdx={realPlayerIdx} playerName={getPlayerName(realPlayerIdx)} aiEngineVersion={aiEngineVersion} truncate />
                  </div>
                  <ShotAccuracy total={insights?.player_data[realPlayerIdx].shot_count} playerIdx={realPlayerIdx} stats={playerStats[realPlayerIdx].shotAccuracy} playerName={getPlayerName(realPlayerIdx)} />
                </div>
                <div>
                  <ShotAverageStats playerIdx={realPlayerIdx} playerStats={playerStats} />
                  <SinglePlayerPerformanceChart playerIdx={realPlayerIdx} playerName={getPlayerName(realPlayerIdx)} vid={vid} playerShots={shotsByQualityPerPlayer && shotsByQualityPerPlayer[realPlayerIdx]} />
                </div>
              </div>
              <SelectedShotsViewer playerIdx={realPlayerIdx} shotNumberSelection={shotNumberSelection} onShotNumberSelectionChange={onShotNumberSelectionChange} />
            </div>
          </SkeletonWrapper>
        </div>
        <div className='insights-row-grid'>
          <SkeletonWrapper isLoading={!isWorkflowDone} height={300} rootClassName='half'>
            <div className='half'>
              <CourtCoverageGraph playerIdx={realPlayerIdx} insights={insights} />
            </div>
          </SkeletonWrapper>
          <SkeletonWrapper isLoading={!isWorkflowDone} height={300} rootClassName='half'>
            <div className='half'>
              <ServesReturnsViewer playerName={getPlayerName(realPlayerIdx)} insights={insights} playerIdx={realPlayerIdx} />
              <ServesReturnGraph playerName={getPlayerName(realPlayerIdx)} insights={insights} playerIdx={realPlayerIdx} />
            </div>
          </SkeletonWrapper>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  '& .shot-viewer': {
    ...row,
    gap: theme.spacing(2)
  },
  '& .player-info': {
    ...column,
    width: 300,
    maxWidth: 300,
    gap: theme.spacing(2),
    '& > div': {
      ...column,
      gap: theme.spacing(2)
    }
  },
  '& .player-avatar': {
    maxWidth: 300
  },
  '& .player-name': {
    ...row,
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  '& .player-shots-graph': {
    marginTop: theme.spacing(2)
  },
  [theme.breakpoints.down('lg')]: {
    '& .shot-viewer': {
      ...column
    },
    '& .player-info': {
      ...row,
      width: '100%',
      maxWidth: '100%',
      '& > div': {
        flex: 1
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    '& .player-info': {
      ...column
    }
  }
})
