import React, { cloneElement, useMemo, useState } from 'react'

import UpgradeToAccessDialog from '../dialog/upgrade-to-access'

import { SUBSCRIPTION_PLANS } from '@/hooks/monetization'
import { DEMO_VIDEOS } from '@/pages/anonymous-upload'
import { useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import { assert } from '@/utils'

/**
 * Component designed to be wrapped around clickable elements to restrict access
 * to features based on the user's subscription level. If the user does not have
 * the required subscription, a dialog will be triggered instead of allowing the
 * original click action to proceed.
 *
 * @param {Object} props The properties for the FeatureGuard component.
 * @param {string} props.requiredSubscription The required subscription tier to access the feature.
 * @param {string} props.video The video being guarded.
 * @param {string} props.hasOpacity When this is true, component will have the opacity.
 * @param {React.ReactNode} props.children The clickable element(s) guarded by the subscription status.
 * @returns {React.ReactElement} A component that conditionally allows clicks based on the subscription level or triggers a dialog.
 *
 * @example
 * <FeatureGuard video={video} requiredSubscription={SUBSCRIPTION_PLANS.PREMIUM}>
 *   <MenuItem onClick={openRawDataDialog} disabled={!isWorkflowDone}>
 *     <ListItemIcon>
 *       <TextSnippetIcon fontSize="small" />
 *     </ListItemIcon>
 *     Download Raw Data
 *   </MenuItem>
 * </FeatureGuard>
 */

const DEMO_VIDS = DEMO_VIDEOS.map((video) => video.vid)

export default function FeatureGuard ({ video, requiredSubscription, hasOpacity, children }) {
  // ensure requiredSubscription is valid value
  assert(Object.values(SUBSCRIPTION_PLANS).indexOf(requiredSubscription) !== -1,
    'unknown subscription tier required')

  const { hasPremiumAccess, hasStarterAccess } = useMyMonetizationData()
  const [dialogOpen, setDialogOpen] = useState(false)
  const loggedInUser = useLoggedInUser()
  const firstSuccessVID = loggedInUser?.firstSuccessVID

  const isAllowed = useMemo(() => {
    // demo videos are fully available to everyone
    if (DEMO_VIDS.includes(video.vid)) {
      return true
    }

    // video from an invoice partner are fully available to everyone
    if (video?.src.invoice) {
      return true
    }

    // if this video was the first one the user ever successfully uploaded,
    // then they get premium access to it
    if (video?.vid === firstSuccessVID) {
      return true
    }

    // if a user has premium access, then everything is allowed for them
    if (hasPremiumAccess) {
      return true
    }
    // even if the user doesn't have premium access, they can still access
    // starter-only features if they have a starter subscription
    if (requiredSubscription === SUBSCRIPTION_PLANS.STARTER && hasStarterAccess) {
      return true
    }
    // if we get here, premium access was required but the user does not have it
    return false
  }, [firstSuccessVID, hasPremiumAccess, hasStarterAccess, requiredSubscription, video])

  const handleChildClick = (event) => {
    // If child is already disabled, then dont even bother showing dialog
    if (children.props?.disabled) {
      return
    }
    // Prevent the original click action if the subscription is not appropriate
    if (!isAllowed) {
      setDialogOpen(true)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const renderChildren = () => {
    const originalOnClick = children.props?.onClick

    const onClickHandler = (event) => {
      // Call the original onClick if the event was not stopped
      if (!isAllowed) {
        event.stopPropagation()
        return
      }
      originalOnClick && originalOnClick(event)
    }

    return (
      <div onClickCapture={handleChildClick}>
        {React.Children.map(children, child =>
          cloneElement(child, {
            onClick: onClickHandler,
            style: {
              ...child.props.style,
              opacity: child.props?.disabled || (hasOpacity && !isAllowed) ? 0.3 : 1,
              pointerEvents: child.props?.disabled || (hasOpacity && !isAllowed) ? 'none' : 'auto'
            }
          }))}
      </div>
    )
  }

  return (
    <>
      {renderChildren()}
      <UpgradeToAccessDialog isOpen={dialogOpen} setIsOpen={setDialogOpen} requiredSubscription={requiredSubscription} />
    </>
  )
}
