import AddIcon from '@mui/icons-material/Add'
import PersonIcon from '@mui/icons-material/Person'
import { styled } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from '../../button'

import { UploadButton } from '@/components/upload/UploadButton'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import { removeBanner } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'
import { getDaysLeft } from '@/utils/time'

export const CreateAccountActions = () => {
  const dispatch = useDispatch()
  const isMobile = useMobileDetect()

  function handleBannerClose () {
    dispatch(removeBanner(BANNER_KEYS.ANONYMOUS_PROMPT_TO_CREATE_ACCOUNT))
  }
  return (
    <Container className={isMobile && 'mobile'}>
      <Button LinkComponent={Link} to='/create-account' onClick={handleBannerClose} className={cls('green')}>
        Create a Free Account <PersonIcon />
      </Button>
      <span>No credit card required!</span>
      <Button LinkComponent={Link} to='/login' onClick={handleBannerClose} className={cls('sing-in-button', 'transparent')}>Sign in</Button>
    </Container>
  )
}

export const BuyMoreCreditsMessage = () => {
  const md = useMyMonetizationData()
  function beginning () {
    if (md && md.currentSubscription && md.currentSubscription.autoRenew) {
      return <>As a subscriber, you will receive Credits when your subscription renews on <strong>{new Date(md.currentSubscription.end).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</strong>.</>
    } else {
      return <>Your subscription is not set to renew. If you renew your subscription, you will receive additional Credits on the renewal date</>
    }
  }
  return (
    // eslint-disable-next-line react/no-unescaped-entities
    <>
      {beginning()} If you’d like to upload your video now, you can buy more credits here:
    </>

  )
}

export const BuyMoreCreditsBannerActions = () => {
  return (
    <Button className={cls('green')} LinkComponent={Link} to='/settings/credits'>Buy More Credits <AddIcon /></Button>
  )
}

export const ExploreSubscriptionPlansActions = () => {
  const dispatch = useDispatch()
  return (
    <Button LinkComponent={Link} to='/subscribe' onClick={() => dispatch(removeBanner(BANNER_KEYS.UNSUBSCRIBED_OUT_OF_CREDIT))} className={cls('green')}>Explore Subscription Plans <PersonIcon /></Button>
  )
}

export const UpgradeBeforeFreeTrialEndsMessage = () => {
  const { creditsLeft, freeTrialExpirationEpoch, isLoading } = useMyMonetizationData()
  const user = useLoggedInUser()

  if (isLoading || !user) {
    return
  }

  function daysLeftText () {
    const daysRemaining = getDaysLeft(freeTrialExpirationEpoch)
    if (daysRemaining > 1) {
      return `${Math.floor(daysRemaining)} days left`
    } else {
      return 'Last day'
    }
  }

  return (
    // eslint-disable-next-line react/no-unescaped-entities
    <>
      {daysLeftText()} - you can analyze {creditsLeft} more game{creditsLeft > 1 ? 's' : ''} for free
    </>

  )
}

export const UpgradeBeforeFreeTrialEndsActions = () => {
  const dispatch = useDispatch()
  return (
    <Container>
      <Button
        className='upgrade-button'
        to='/subscribe'
        LinkComponent={Link}
        onClick={() => dispatch(removeBanner(BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL))}
      >
        <em>Upgrade</em>
      </Button>
    </Container>
  )
}

export const TrialModeExpired = () => {
  const dispatch = useDispatch()
  return (
    <Container>
      <Button
        className='upgrade-button'
        to='/subscribe'
        LinkComponent={Link}
        onClick={() => dispatch(removeBanner(BANNER_KEYS.FREE_TRIAL_EXPIRED))}
      >
        <em>Learn About Premium</em>
      </Button>
    </Container>
  )
}

export const TrialHaventStart = () => {
  return (
    <Container>
      <UploadButton />
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '16px',
  '& span': {
    fontSize: '14px',
    fontStyle: 'italic',
    color: COLORS['neutral-700']
  },
  '& .sing-in-button': {
    marginLeft: 'auto',
    color: COLORS['neutral-700'],
    border: `1px solid ${COLORS['warning-500']}`
  },
  '& .upgrade-button': {
    border: `1px solid ${COLORS['neutral-300']}`
  },
  '&.mobile': {
    '& span': {
      order: 3
    },
    '& .sing-in-button': {
      marginLeft: 0
    }
  }
})
