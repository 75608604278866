import { styled } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import AllPlayerPerformanceCharts from '../../components/all-player-performance-charts'
import GameOverview from '../../components/game-overview'
import { usePlayersShotQuality } from '../../hooks/use-players-shot-quality'

import { Counter } from '@/components/counter'
import { ExperimentalIcon } from '@/components/experimental-icon'
import { SkeletonWrapper } from '@/components/skeleton'
import ProcessingProgress from '@/components/video-player/processing-progress'
import { APIContext } from '@/utils/api'
import theme from '@/utils/theme'

export default function GameStatsTab () {
  const { vid } = useParams()
  const { getPlayerName, insights, aiEngineVersion, video, workflow } = useContext(APIContext)
  const { getShotQualityForAllShotsPerPlayer } = usePlayersShotQuality()
  const isWorkflowDone = Boolean(workflow?.epochFinished)

  const shotsByQualityPerPlayer = useMemo(() => {
    const qualityPerPlayerObj = getShotQualityForAllShotsPerPlayer(insights)

    return qualityPerPlayerObj
  }, [getShotQualityForAllShotsPerPlayer, insights])

  return (
    <Container>
      <div className='insights-group'>
        <div className='head'>
          <h1>Player Performance</h1>
          <ExperimentalIcon />
        </div>
        <div className='insights-row-grid'>
          {workflow && !isWorkflowDone && (
            <ProcessingProgress
              vid={vid}
              workflowId={workflow.workflowId}
              workflowIndex={0}
              className='video-column'
            />
          )}
          <SkeletonWrapper isLoading={!isWorkflowDone} height={350} rootClassName='video-column'>
            <div className='video-column'>
              <AllPlayerPerformanceCharts shotsByQualityPerPlayer={shotsByQualityPerPlayer} vid={vid} getPlayerName={getPlayerName} isWorkflowDone={isWorkflowDone} />
            </div>
          </SkeletonWrapper>
          <SkeletonWrapper isLoading={!isWorkflowDone} height={190} rootClassName='video-column'>
            <div className='video-column'>
              <GameOverview vid={vid} aiEngineVersion={aiEngineVersion} video={video} insights={insights} />
            </div>
          </SkeletonWrapper>

          <SkeletonWrapper isLoading={!isWorkflowDone} height={80} rootClassName='video-column'>
            <div className='video-column'>
              <Counter insights={insights} />
            </div>
          </SkeletonWrapper>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  '& .head': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
})
