import { Divider, styled } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import ArrowLeftIcon from '@/assets/arrow-left.svg?react'
import BugIcon from '@/assets/bug-icon.svg?react'
import emojiUrl from '@/assets/emoji-grimacing.png'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { isDebugMode } from '@/utils'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

/** Returns true if the video was paid for (even if it was refunded later). */
export function wasVideoPaidFor (video) {
  for (const workflow of video.workflows) {
    if (workflow.chargedUID) {
      return true
    }
  }
  return false
}

/** Returns true if the video was paid for and NOT refunded. */
export function isVideoPaidFor (video) {
  for (const workflow of video.workflows) {
    if (workflow.chargedUID && !workflow.refunded) {
      return true
    }
  }
  return false
}

export default function ProcessingFailed ({ video, workflow }) {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()

  function onBackToLibraryHandler () {
    navigate('/library')
  }

  function onContactUsHandler () {
    toggleZendeskWidget()
  }

  const IfErrorStateExist = () => {
    if (video.src.duration / 60 > 30) {
      return (
        <div className='info-container'>
          <strong className='info-title'>
            Sorry, your video was more than 30 minutes long. We don’t currently support videos of
            this length.
          </strong>
        </div>
      )
    }
    return (
      <div className='info-container'>
        <strong className='info-title'>
          Sorry, this video is taking longer than expected to analyze.
        </strong>
        {isDebugMode && (
          <pre style={{ background: 'white' }}>{JSON.stringify(workflow.error, null, 4)}</pre>
        )}
        <p>Our team has been notified.</p>
        <p>Don’t worry, if we can’t finish analyzing your video, we’ll refund your Credit.</p>
        <p>Your experience is top priority – rest assured, we’re on it!</p>
        <div className='buttons'>
          <Button className='icon-left green' onClick={() => onBackToLibraryHandler()}>
            <ArrowLeftIcon /> Back to Library
          </Button>
          <Button className='gray' onClick={() => onContactUsHandler()}>
            Contact Us <BugIcon />
          </Button>
        </div>
      </div>
    )
  }
  function nonAbortBody () {
    return workflow?.error
      ? (
        <>
          <div className='heading'>
            <img src={emojiUrl} alt='emoji grimacing' />
            <strong className='title'>Oops! We hit a snag while analyzing your game</strong>
          </div>
          <Divider />
          <IfErrorStateExist />
        </>
        )
      : (
        <>
          <div className='heading'>
            <strong className='title'>📹 Our AI wasn’t able to analyze this video</strong>
          </div>
          <Divider />
          <div className='info-container'>
            <strong className='info-title'>
              This is likely a framing issue. Please verify that the video is properly framed for
              optimal use of our AI Engine.
            </strong>
            {(wasVideoPaidFor(video) && !isVideoPaidFor(video))
              ? <p>The Credit used to analyze this video has been refunded.</p>
              : null}

            <div className='gif-container'>
              <img
                className='gif'
                src='https://fb-usercontent.fra1.cdn.digitaloceanspaces.com/0191c865-6aa7-70bf-ac16-9ad37392d279.gif'
                alt='correct frame gif'
              />
            </div>
            <div className='learn-more'>
              <Link to='https://help.pb.vision/articles/1108176'>
                <Button variant='contained' color='primary'>
                  Learn more
                </Button>
              </Link>
            </div>
          </div>
        </>
        )
  }

  if (workflow?.abort) {
    return null
  }

  return (
    <Container className={isMobile ? 'mobile' : ''}>
      {nonAbortBody()}
    </Container>
  )
}

const Container = styled('div')({
  borderRadius: '12px',
  border: `1px solid ${COLORS['warning-500']}`,
  background: COLORS['warning-050'],
  color: COLORS['neutral-700'],
  fontSize: 14,
  gap: 16,
  marginTop: 16,
  padding: 32,
  ...column,
  '& .row': {
    alignItems: 'flex-end',
    ...row
  },
  ' .heading': {
    alignItems: 'center',
    gap: 10,
    ...row
  },
  '& img': {
    width: 25
  },
  '& hr': {
    width: '100%'
  },
  '& .buttons': {
    gap: 16,
    marginBlock: 8,

    ...row,
    '& .back': {
      color: COLORS.white,
      backgroundColor: COLORS['success-700']
    }
  },
  '& .title': {
    fontSize: 24
  },
  '& .info-container': {
    alignItems: 'flex-start',
    width: '100%',
    gap: 10,
    ...column,
    '& .info-title': {
      fontSize: 16
    }
  },
  '& .info': {
    gap: 8,
    flexShrink: 0,
    alignItems: 'flex-start',
    ...row,
    '& a': {
      color: COLORS['neutral-700']
    },
    '& div': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      '& strong': {
        textWrap: 'nowrap'
      }
    },
    '& svg': {
      minWidth: 24,
      '&.bad path': {
        fill: COLORS.error
      },
      '&.good path': {
        fill: COLORS['success-700']
      }
    }
  },
  '& .learn-more': {
    width: '100%',
    marginTop: 16,
    alignItems: 'center',
    gap: 8,
    ...row,
    '& button': {
      background: COLORS.white,
      border: `1px solid ${COLORS['neutral-300']}`,
      '&:hover': {
        background: COLORS['neutral-050'],
        borderColor: COLORS['neutral-400']
      }
    }
  },
  '& .gif-container': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  '.gif': {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },

  // responsive
  '&.mobile': {
    padding: 16,
    marginInline: 8,
    '& .info': {
      '& div': {
        ...column
      }
    }
  }
})
