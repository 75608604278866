import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DEMO_VIDEOS } from '../anonymous-upload'

import Page from './index'

function Redirect ({ vid }) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(`/video/${vid}`, { replace: true })
  }, [navigate, vid])
}

export default [
  {
    path: '/video/:vid/:tab?/:tabId?',
    element: <Page />
  },
  {
    path: '/video/tvgz3pqij0ll',
    element: <Redirect vid={DEMO_VIDEOS[0].vid} />
  }
].concat(
  // special links for demo videos (first video will always use `/videos/demo` path )
  DEMO_VIDEOS.map((v, idx) => ({
    path: `/video/${idx ? v.name.toLowerCase().replaceAll(' ', '-') : 'demo'}`,
    element: <Redirect vid={v.vid} />
  }))
)
