import { Dialog, styled } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BarChart } from '@/components/bar-chart'
import { ShotsDialog } from '@/components/dialog/shots-dialog'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { column } from '@/utils/flexGrid'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'
import { shotQualityLevels } from '@/utils/shot-quality-levels'

export default function SinglePlayerPerformanceChart (props) {
  const ref = useRef(null)
  const navigate = useNavigate()
  const { playerShots, playerName, playerIdx, vid } = props
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 })
  const [dialogData, setDialogData] = useState(null)
  const isMobile = useMobileDetect('sm')

  const values = useMemo(() => {
    if (!playerShots) return []
    return [
      { value: playerShots[4].percentage, fillColor: shotQualityLevels[4].color },
      { value: playerShots[3].percentage, fillColor: shotQualityLevels[3].color },
      { value: playerShots[2].percentage, fillColor: shotQualityLevels[2].color },
      { value: playerShots[1].percentage, fillColor: shotQualityLevels[1].color },
      { value: playerShots[0].percentage, fillColor: shotQualityLevels[0].color }
    ].filter(value => value.value)
  }, [playerShots])

  function createExploreUrl (minQuality, maxQuality, playerId) {
    const query = {
      quality: {
        min: minQuality,
        max: maxQuality
      },
      players: [playerId]
    }

    // Construct the full URL
    const url = `explore?filters=${encodeURI(JSON.stringify(query))}`
    return url
  }

  function onRedirectToShotExplorer (level, playerIdx) {
    const step = 0.2
    const minQuality = step * level
    const maxQuality = minQuality + step

    const url = createExploreUrl(minQuality.toFixed(1), maxQuality.toFixed(1), playerIdx)
    scrollToTopOfMain()
    navigate(`/video/${vid}/${url}`)
  }

  function handleSingleChartClick () {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const qualityKeys = Object.keys(shotQualityLevels)
      // Buttons are in reverse sequence so we need to invert the index value
      const totalLevels = qualityKeys.length - 1

      const buttonsForShotDialog = qualityKeys.reverse().map(level => {
        return {
          color: shotQualityLevels[level].color,
          label: shotQualityLevels[level].label,
          shotCount: playerShots[level].count,
          callback: (idx, playerIdx) => onRedirectToShotExplorer(totalLevels - idx, playerIdx)
        }
      })

      // Calculate position above the clicked element
      setDialogPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      })
      setDialogData({ buttonsForShotDialog, playerIdx, playerName })
    }
  }

  function onCloseDialog () {
    setDialogData(null)
  }

  const dialogDesktopStyle = {
    position: 'absolute',
    top: '50%', // Vertically center the dialog
    left: dialogPosition.left + (dialogPosition.width / 2), // Horizontally position based on clicked element
    transform: 'translateY(-50%) translateX(-50%)',
    margin: 0
  }

  if (!playerShots) {
    return `No data for ${playerName}`
  }

  return (
    <Container ref={ref} className='player-shots-graph'>
      <BarChart
        width='100%'
        sx={{ height: '130px' }}
        withoutLabels
        isValuesRounded={false}
        values={values}
        onClick={() => handleSingleChartClick()}
        isVertical
      />
      <Dialog
        onBackdropClick={onCloseDialog}
        open={Boolean(dialogData)}
        PaperProps={{
          style: !isMobile ? dialogDesktopStyle : {}
        }}
      >
        <ShotsDialog buttons={dialogData?.buttonsForShotDialog} onClose={onCloseDialog} playerIdx={dialogData?.playerIdx} playerName={dialogData?.playerName} />
      </Dialog>
    </Container>

  )
}

const Container = styled('div')({
  ...column,
  width: '100%',
  marginInline: 'auto',
  cursor: 'pointer',
  position: 'relative'
})
