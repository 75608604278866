import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { isProd, millinow, useCurrentEpochAndUpdateWhenNeeded } from '../utils'

import { useIsLoggedIn, useLoggedInUser, useMyMonetizationData } from '@/store/auth'
import { removeBanner, showBanner } from '@/store/controls'
import { BANNER_KEYS } from '@/utils/banners'
import { isInIOSWebview, isInMobileAppWebview, isVersionedFeatureAllowed } from '@/utils/mobile-app-communication'

export const SUBSCRIPTION_PLANS = {
  STARTER: 'starter',
  PREMIUM: 'premium'
}

export const PLAN_COSTS_ANNUAL = {
  [SUBSCRIPTION_PLANS.STARTER]: isInIOSWebview() ? 179.99 : 179.99,
  [SUBSCRIPTION_PLANS.PREMIUM]: isInIOSWebview() ? 399 : 396 // Apple doesn't support $396 like other platforms
}

export function useMonetizationData (data) {
  const { epoch, setEpochWhenUpdateNeeded } = useCurrentEpochAndUpdateWhenNeeded()
  const isLoggedIn = useIsLoggedIn()

  return useMemo(() => {
    if (!data?.credits) {
      // Show the loader only if the user is logged in; otherwise, the returned data is always null
      return { isLoading: !!isLoggedIn }
    }

    const currentSubscription = getSubscriptionAtEpoch(epoch, data.subscriptions)
    const giftSubscription = extractGiftSubscription(data)

    if (currentSubscription && currentSubscription.end / 1000 > epoch) {
      setEpochWhenUpdateNeeded(currentSubscription.end / 1000)
    }
    // Basically, the trial doesn’t really start until the first upload is done.
    // 1730415600 is the end of Oct-2024 so long-standing users aren’t cut off right away when we release monetization.
    const thirtyOneDaysInSeconds = 31 * 86400
    const freeTrialExpirationEpoch = Math.max(isProd ? 1730415600 : 0, (data.firstUploadEpoch ?? epoch) + thirtyOneDaysInSeconds)
    const ret = {
      asOfEpoch: epoch,
      creditsLeft: data.credits.left,
      currentSubscription,
      giftSubscription,
      freeTrialExpirationEpoch,
      isFreeTrialExpired: freeTrialExpirationEpoch <= epoch,
      isSubscriber: Boolean(currentSubscription),
      subscriptions: data.subscriptions
    }
    // premium tier benefits until the free trial expires
    const hasPremiumAccess = (
      // has a premium plan
      ret.currentSubscription?.tier === SUBSCRIPTION_PLANS.PREMIUM ||
      // or is on the free trial
       epoch < freeTrialExpirationEpoch ||
       // or has a gift subscription
       giftSubscription?.type === SUBSCRIPTION_PLANS.PREMIUM
    )
    const hasStarterAccess = hasPremiumAccess || giftSubscription?.type === SUBSCRIPTION_PLANS.STARTER

    ret.hasPremiumAccess = hasPremiumAccess
    ret.hasStarterAccess = hasStarterAccess
    return ret
  }, [data, epoch, setEpochWhenUpdateNeeded, isLoggedIn])
}

function getSubscriptionAtEpoch (epoch, subscriptions) {
  const milliEpoch = epoch * 1000
  for (const sub of subscriptions) {
    if (milliEpoch >= sub.start && milliEpoch < sub.end) {
      return sub
    }
  }
  return null // not subscribed at epoch
}

function extractGiftSubscription (data) {
  const giftSubscription = data?.giftSubscription?.endByTier
  if (!giftSubscription) {
    return null
  }

  if (giftSubscription.premium && giftSubscription.premium >= millinow()) {
    return {
      type: SUBSCRIPTION_PLANS.PREMIUM,
      end: giftSubscription.premium,
      endDateLabel: dayjs(giftSubscription.premium).format('M/D/YY')
    }
  }

  if (giftSubscription.starter && giftSubscription.starter >= millinow()) {
    return {
      type: SUBSCRIPTION_PLANS.STARTER,
      end: giftSubscription.starter,
      endDateLabel: dayjs(giftSubscription.starter).format('M/D/YY')
    }
  }

  return null
}

// temporary
export function useIsBuyingEnabled () {
  // buying is enabled on test for everyone
  if (!isProd) {
    return true
  }
  // buying from within the native app's webview is disabled on prod because
  // the native apps do not yet support it
  if (isInMobileAppWebview()) {
    if (isInIOSWebview()) {
      return isVersionedFeatureAllowed('2.0.10')
    }
    return isVersionedFeatureAllowed('2.0.9')
  }
  // buying is enabled for everyone on web
  return true
}

/** Hook that will show free trial banner according to subscriber and free trial status. */
export function useShowFreeTrialBanner () {
  const dispatch = useDispatch()
  const md = useMyMonetizationData()
  const isLoggedIn = useIsLoggedIn()
  const loggedInUser = useLoggedInUser()

  useEffect(() => {
    // If user is logged in then don't run this useEffect
    if (isLoggedIn) {
      return
    }

    // As soon as user is not logged in, we want to remove the banners
    dispatch(removeBanner(BANNER_KEYS.FREE_TRIAL_EXPIRED))
    dispatch(removeBanner(BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL))
  }, [isLoggedIn, dispatch])

  // isSubscriber means that the user is subscribed and not on free trial
  useEffect(() => {
    (() => {
      if (md.isLoading) {
        return
      }

      // This banner should only be shown to subscribers and logged in users
      if (md.isSubscriber || md.giftSubscription || !isLoggedIn) {
        dispatch(removeBanner(BANNER_KEYS.FREE_TRIAL_EXPIRED))
        dispatch(removeBanner(BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL))
        dispatch(removeBanner(BANNER_KEYS.TRIAL_HAVENT_START))
        return
      }

      // If user is not subscribed and free trial is expired, show the free trial expired banner
      if (md.isFreeTrialExpired) {
        dispatch(showBanner(BANNER_KEYS.FREE_TRIAL_EXPIRED))
      } else {
        if (loggedInUser.firstUploadEpoch) {
          /**
           * If user has uploaded a video (which means that user has active free trial),
           * show the upgrade before free trial ends banner
           */
          dispatch(showBanner(BANNER_KEYS.UPGRADE_BEFORE_FREE_TRIAL))
        }
      }

      // If user hasn't uploaded any video yet, show the trial haven't started banner
      if (!(loggedInUser?.firstUploadEpoch)) {
        dispatch(showBanner(BANNER_KEYS.TRIAL_HAVENT_START))
      }
    })()
  }, [dispatch, md.isLoading, md.isSubscriber, md.isFreeTrialExpired, isLoggedIn, loggedInUser, md.giftSubscription])
}

export function useMonetizationPricing () {
  function getAnnuallyCostByPlan (plan) {
    const cost = PLAN_COSTS_ANNUAL[plan]
    return isInIOSWebview() ? cost.toFixed(2) : cost.toFixed(0)
  }

  function getMonthlyCostByPlan (plan) {
    const cost = PLAN_COSTS_ANNUAL[plan]
    return Math.round(cost / 12).toFixed(0)
  }

  return { getAnnuallyCostByPlan, getMonthlyCostByPlan }
}
