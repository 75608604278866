import { styled } from '@mui/material'
import React from 'react'

import StatsSectionTitle from '../components/stats-section-title'

import CourtServesReturns from '@/assets/court-serves-returns.svg?react'
import { Avatar } from '@/components/avatar'
import { ServesReturns } from '@/components/serves-and-returns'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import theme from '@/utils/theme'

export default function ServesReturnGraph ({ playerIdx, insights, playerName }) {
  if (!insights) {
    return null
  }

  const isRounded = playerIdx < 2

  return (
    <Container>
      <StatsSectionTitle tooltip={`This section shows the depth of ${playerName}'s serves and returns during this game.`} title='Serve & Return Depth' />
      <div className='graphs'>
        <div className='graph-description'>
          <span className='desc-subtitle'>
            Depth
          </span>
          <CourtServesReturns className='serves-return-court' />
          <Avatar width='26' initials={playerName} round={isRounded} className={cls('avatar', `player-${playerIdx}`, !isRounded && 'square')} />
        </div>
        <ServesReturns insights={insights} playerIdx={playerIdx} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  marginTop: theme.spacing(2),
  '& .graph-description': {
    ...column,
    gap: theme.spacing(1),
    width: 200,
    minWidth: 180,
    color: COLORS.black,
    alignItems: 'flex-end',
    position: 'relative',
    '& .desc-subtitle': {
      fontSize: 12,
      color: COLORS['neutral-500']
    },
    '& .avatar': {
      alignSelf: 'flex-end',
      marginTop: -35,
      marginRight: 47
    }
  },
  '& .graphs': {
    ...row,
    justifyContent: 'space-between'
  },
  [theme.breakpoints.down('lg')]: {
    '& .graphs': {
      ...column,
      alignItems: 'center'
    },
    '& .graph-description': {
      ...column,
      width: 250,
      marginLeft: -100,
      alignItems: 'flex-start',
      '& .desc-subtitle': {
        marginLeft: 'auto'
      },
      '& .avatar': {
        marginRight: 60,
        marginTop: -45,
        '&.square': {
          marginRight: 62
        }
      }
    },
    '& .serves-return-court': {
      width: '100%'
    }
  }
})
