import { useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGetInsights, useGetVideo, useGetVideoMetadata } from '@/store/video'
import { APIWrapper } from '@/utils/api'

function HomeAPIWrapper (props) {
  const {
    children,
    muxPlayerRef
  } = props
  const params = useParams()
  const searchParams = useSearchParams()[0]

  const [rallyNumber, setRallyNumber] = useState(1)
  const [isTheaterModeEnabled, setTheaterModeEnabled] = useState(false)
  const [savedPresetId, setSavedPresetId] = useState(1)

  const vid = params.vid
  const getInsightsArgs = { vid }
  const localMuxPlayerRef = useRef(null)
  // Use props provided muxPlayerRef and fallback to local one when not set
  const playerRef = muxPlayerRef ?? localMuxPlayerRef
  const workflowId = searchParams.get('workflowId')

  if (workflowId) {
    getInsightsArgs.workflowId = workflowId
  }
  return (
    <APIWrapper
      apis={[
        { api: useGetVideo, args: vid, waitForLoad: true, name: 'video', needsUseAPI: false },
        { api: useGetInsights, args: getInsightsArgs, waitForLoad: false, name: 'insights', needsUseAPI: false },
        { api: useGetVideoMetadata, args: vid, waitForLoad: true, name: 'videoMetadata', needsUseAPI: false }
      ]}
      addContext={ctx => {
        // Add video-player related stuff
        ctx.muxPlayerRef = playerRef
        ctx.setRallyNumber = setRallyNumber
        ctx.rallyNumber = rallyNumber
        ctx.isTheaterModeEnabled = isTheaterModeEnabled
        ctx.setTheaterModeEnabled = setTheaterModeEnabled
        ctx.savedPresetId = savedPresetId
        ctx.setSavedPresetId = setSavedPresetId
        ctx.getPlayerName = playerIdx => {
          const { players } = ctx.video.userData
          return players[playerIdx]?.name || `Player ${playerIdx + 1}`
        }

        // if the insights data isn't the right version, don't try to use it
        if (ctx.insights) {
          ctx.insightsVersion = ctx.insights.version
          ctx.isInsightsVersionOkay = ctx.insights.version.startsWith('2.')
          if (!ctx.isInsightsVersionOkay) {
            ctx.insights = undefined
          }
        }

        // add workflow to context (extracted from video object to be the one
        // that was requested by the user)
        const { workflows } = ctx.video
        if (!workflowId) {
          // workflows[0] could be undefined if no workflow has started yet
          const latestWorkflow = workflows[0]
          return { workflow: latestWorkflow, ...ctx }
        }
        for (const workflow of workflows) {
          if (workflow.workflowId === workflowId) {
            return { workflow, ...ctx }
          }
        }
        return ctx // workflow not found
      }}
    >
      {children}
    </APIWrapper>
  )
}

export default HomeAPIWrapper
