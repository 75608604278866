export function scrollToTopOfMain () {
  const mainElt = document.getElementById('pbvision') ?? document.getElementById('root')
  if (mainElt) {
    mainElt.scrollTo(0, 0)
  }
}

export function scrollTo ({ x = 0, y = 0 }) {
  const mainElt = document.getElementById('pbvision') ?? document.getElementById('root')
  if (mainElt) {
    mainElt.scrollTo(x, y)
  }
}
