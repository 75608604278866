import { signInWithCustomToken } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import safeJsonStringify from 'safe-json-stringify'

import { addAnonymousVideosFromNativeApp } from '@/store/anonymous'
import { apisSlice } from '@/store/apis'
import { refreshToken, setPushToken, useLoggedInUserCredentials } from '@/store/auth'
import { addReceivedWebViewCommand, setReferralCode } from '@/store/controls'
import { uploadFromNativeAppDone } from '@/store/uploads'
import { isProd } from '@/utils'
import { getAuth } from '@/utils/firebase'

function parseVersion (versionStr) {
  const pieces = versionStr.split('.')
  return {
    major: +pieces[0],
    minor: +pieces[1],
    patch: +pieces[2]
  }
}

export const webviewLocalStorageKey = 'pbv-native-info'

// whether this web app is running inside a webview inside a native mobile app
// is determined just once when the app starts
function getMobileAppPlatform () {
  const qs = new URLSearchParams(window.location.search)
  const app = qs.get('app')
  if (app) {
    const version = parseVersion(qs.get('version') ?? '2.0.0')
    const build = qs.get('build') ?? null
    window.localStorage.setItem(webviewLocalStorageKey, JSON.stringify({ app, version, build }))
    return { app, version, build }
  }
  return JSON.parse(window.localStorage.getItem(webviewLocalStorageKey) ?? '{}')
}
const mobileAppPlatform = getMobileAppPlatform()

/**
 * Returns whether this web app is running inside a native mobile app's webview.
 *
 * @returns {boolean} whether we're running inside a native mobile app's webview
 */
export function isInMobileAppWebview () {
  return !!mobileAppPlatform.app
}

export function isInAndroidWebview () {
  return mobileAppPlatform.app === 'android'
}

export function isInIOSWebview () {
  return mobileAppPlatform.app === 'ios'
}

// returns undefined if not in a webview
export function getNativeAppInfo () {
  return mobileAppPlatform
}

// remove this once v2.0.1 apps are out
export function isVersionedFeatureAllowed (minVersionRequired) {
  if (!isInMobileAppWebview()) {
    return true
  }
  const required = parseVersion(minVersionRequired)
  const current = getMobileAppPlatform().version
  if (current.major > required.major) {
    return true
  }
  if (current.major < required.major) {
    return false
  }
  // if we get here, major versions are equal
  if (current.minor > required.minor) {
    return true
  }
  if (current.minor < required.minor) {
    return false
  }
  // if we get here, major and minor versions are equal
  return current.patch >= required.patch
}

/**
 * Sends a message to the native mobile app in which we're running, if any.
 *
 * @param {string} eventName the name of the event; the mobile app uses this to
 *   decide how to interpret the accompanying data, if any
 * @param {object} [data] optional data describing the event (must be JSONable)
 *
 * @returns {boolean} whether we're running inside a native mobile app's webview
 */
function sendDataToMobileAppIfInWebview (eventName, data) {
  if (!isInMobileAppWebview()) {
    return false
  }
  const jsonEvent = JSON.stringify({ ...(data ?? {}), event: eventName })
  if (isInAndroidWebview()) {
    window.PBVision?.eventFromWebApp?.(jsonEvent)
  } else if (isInIOSWebview()) {
    window.webkit?.messageHandlers?.jsMessageHandler?.postMessage?.(jsonEvent)
  } else {
    throw new Error('tried to send event to unknown app platform')
  }
  if (!isProd) {
    console.log('sent event to native mobile app: ', jsonEvent)
  }
  return true
}

/**
 * Tells the native mobile app in which we're running (if any) to show the
 * native video upload UI.
 *
 * @returns {boolean} true if we're running in a webview and it will handle the
 *   upload process; false if we should show our web-based upload handler
 */
export function startNativeUploadInMobileApp (folderId = undefined) {
  const data = folderId ? { fid: folderId } : undefined
  return sendDataToMobileAppIfInWebview('chooseVideosToUpload', data)
}

/**
 * Tells the native mobile app to change default Hostname
 *
 */
export function notifyChangeHostname (url) {
  const data = { hostname: url }
  sendDataToMobileAppIfInWebview('changeTestHostname', data)
}

/**
 * Tells the native mobile app in which we're running (if any) about the signed
 * in user's credentials. This should be called whenever the user signs in.
 *
 * @param {UserAccountInfo} userData the user's info
 */
export function notifySignedIn ({ email, uid, token }) {
  sendDataToMobileAppIfInWebview('signedIn', { email, uid, token })
}

/**
 * Tells the native mobile app in which we're running (if any) that the user
 * has signed out.
 */
export function notifySignedOut () {
  sendDataToMobileAppIfInWebview('signedOut')
}

/**
 * Tells the native mobile app about the anonymous user's temporary ID.
 */
export function notifyAnonymousUserTemporaryId (anonymousId) {
  sendDataToMobileAppIfInWebview('anonymousUserTemporaryId', { anonymousId })
}

/**
 * Tells the native app to show the SSO screen for the specified provider.
 */
export function notifySSORequested (provider) {
  sendDataToMobileAppIfInWebview('startSSO', { provider })
}

/**
 * Tells the native mobile app to give the user a chance to share debug logs.
 */
export function notifyDebugLogsRequested () {
  sendDataToMobileAppIfInWebview('shareLogs')
}

/**
 * A user's id and credentials.
 *
 * @typedef {object} UserAccountInfo
 * @property {string} uid the user's ID
 * @property {string} token the user's token
 */

/**
 * Tells the native app to change the view from the webview to a native page.
 *
 * @param {string} page the name of the native page to go to (e.g., "uploads")
 */
function goToNativeScreen (page) {
  sendDataToMobileAppIfInWebview('goToNativePage', { page })
}

/**
 * Tells the native app to change the view to the native uploads screen.
 */
export function goToNativeUploadsScreen () {
  goToNativeScreen('upload')
}

/**
 * Tells the native app to change the view to the native upload tips screen.
 */
export function goToNativeUploadTipsScreen () {
  goToNativeScreen('upload-tips')
}

export function notifyShareRequested (url) {
  sendDataToMobileAppIfInWebview('startShare', { url })
}

export function notifySupportRequested () {
  sendDataToMobileAppIfInWebview('startSupport')
}

export function promptToEnablePushNotifications () {
  sendDataToMobileAppIfInWebview('promptToEnablePushNotifications')
}

export function promptPurchaseOnNativeApp (sku) {
  if (isInAndroidWebview()) {
    const androidSKU = {
      credit1: 'gp_credit1',
      credit5: 'gp_credit5',
      credit30: 'gp_credit30',
      annual_starter: 'annual-starter-renewable',
      annual_premium: 'annual-premium'
    }[sku]
    const androidData = { sku: androidSKU }
    if (sku.startsWith('annual_')) {
      androidData.group = 'main'
    }
    sendDataToMobileAppIfInWebview('purchase', androidData)
  } else {
    sendDataToMobileAppIfInWebview('purchase', { sku })
  }
}

/**
 * Notifies the native mobile app in which we're running (if any) whenever the
 * route we're viewing changes.
 *
 * @param {string} newPath the new path we're now displaying
 */
function notifyRouteChanged (newPath) {
  let title = ''
  if (newPath.startsWith('/library')) {
    title = 'Video Library'
  } else if (newPath.startsWith('/video')) {
    title = 'Video'
  } else if (newPath.startsWith('/settings')) {
    if (newPath.startsWith('/settings/choose')) {
      title = 'Settings'
    } else {
      const settingsSubPage = newPath.split('/')[2].split('?')[0]
      title = `Settings - ${settingsSubPage[0].toUpperCase()}${settingsSubPage.slice(1)}`
    }
  } else if (newPath.startsWith('/first_upload_started')) {
    if (newPath.indexOf('email') !== -1) {
      title = 'Set Password'
    } else {
      title = ''
    }
  } else {
    const pieces = newPath.substring(1).split('?')[0].replace(/[-_]/g, ' ').split(' ')
    const titleArr = pieces.map(x => (x[0] ?? '').toLocaleUpperCase() + x.substring(1))
    title = titleArr.join(' ')
  }
  sendDataToMobileAppIfInWebview('routeChanged', { route: newPath, title })
}

/**
 * Should only be used once at the web app's top-level to set up listening for
 * and executing commands requested by the native mobile app.
 *
 * Command: goToRoute - navigates to the specified path
 *   - /library will route to / and it can specify an anonymousVideoObjNames
 *     param; if specified, then the specified videos should be shown
 */
export function useListenForMobileAppCommands () {
  const dispatch = useDispatch()
  const location = useLocation()
  const setLastPathAndQS = useState()[1]
  const navigate = useNavigate()
  const [routeChangeNum, setRouteChangeNum] = useState(0)
  const isInAndroid = isInAndroidWebview()
  const [forceSendRouteChanged, setForceSendRouteChanged] = useState(false)

  useEffect(() => {
    if (!isInMobileAppWebview()) {
      return
    }
    window.updateFromMobileApp = (commandJSON) => {
      const { command: commandName } = commandJSON
      dispatch(addReceivedWebViewCommand(`command: ${commandName}`))
      if (commandName === 'goToRoute') {
        setRouteChangeNum(n => n + 1)
        if (commandJSON.navigateEvenIfAlreadyOnThisPage) {
          setForceSendRouteChanged(true)
        }
        const { route } = commandJSON

        if (!isProd) {
          dispatch(addReceivedWebViewCommand(`route: ${route}`))
        }

        // Check if route contains 'rf' query parameter
        const url = new URL(route, window.location.origin)
        const rf = url.searchParams.get('rf')

        if (rf && isInAndroid) {
          // Save 'rf' value to localStorage
          window.localStorage.setItem('tmp-referral-code', JSON.stringify(rf))
          dispatch(setReferralCode(rf))
        }

        if (route === '/library') {
          // anonymousVideoObjNames may be omitted
          const { anonymousVideoObjNames: vids } = commandJSON
          if (vids && vids.length) {
            dispatch(addAnonymousVideosFromNativeApp(vids))
          }
          navigate(route)
        } else {
          navigate(route)
        }
      } else if (commandName === 'signedInWithSSO') {
        const { uid, token } = commandJSON
        dispatch(nativeAppLoggedInWithSSO({ uid, token }))
      } else if (commandName === 'refreshToken') {
        dispatch(refreshToken())
      } else if (commandName === 'setPushToken') {
        dispatch(setPushToken(commandJSON.pushToken))
      } else if (commandName === 'newUploadDone') {
        const payload = {
          vid: commandJSON.vid,
          fid: commandJSON.fid
        }
        dispatch(uploadFromNativeAppDone(payload))
        dispatch(apisSlice.util.invalidateTags([
          { type: 'VideoExcerpt', id: 'Library' }
        ]))
      } else if (commandName === 'purchaseDone') {
        const { result, error } = commandJSON
        navigate(`/purchase_done?result=${result}&error=${error}`)
      } else {
        throw new Error(`unknown command from mobile app: ${safeJsonStringify(commandJSON)}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate])

  // notify the native mobile app whenever the token changes
  const { token } = useLoggedInUserCredentials()
  useEffect(() => {
    if (token) {
      sendDataToMobileAppIfInWebview('newToken', { token })
    }
  }, [token])

  // notify the native mobile app whenever we browse to a new route
  useEffect(() => {
    if (isInMobileAppWebview()) {
      const qs = location.search || ''
      const newLastPathAndQS = `${location.pathname}${qs}`
      setLastPathAndQS(old => {
        // treat /video as a single route which we only report route changes
        // when first coming to the page
        const oldVid = getVidIfVideoPagePath(old)
        const newVid = getVidIfVideoPagePath(newLastPathAndQS)
        const isNavWithinVideoPage = oldVid && newVid && oldVid === newVid
        if (!isNavWithinVideoPage || forceSendRouteChanged) {
          notifyRouteChanged(newLastPathAndQS)
          if (forceSendRouteChanged) {
            setForceSendRouteChanged(false)
          }
        }
        return newLastPathAndQS
      })
    }
  }, [forceSendRouteChanged, location.pathname, location.search, routeChangeNum, setLastPathAndQS])
}

function getVidIfVideoPagePath (path) {
  if (path?.startsWith('/video')) {
    if (path[6] === undefined || path[6] === '/') {
      return path.slice(7, 19)
    }
  }
  return false
}

function nativeAppLoggedInWithSSO ({ token, uid }) {
  return async () => {
    console.log('finalizing SSO sign in from native', uid)
    const resp = await window.fetch(`${import.meta.env.VITE_API_SERVER}/user/sso`, {
      method: 'POST',
      headers: {
        'x-uid': uid,
        'x-token': token
      }
    })
    const respBody = await resp.text()
    if (!resp.ok) {
      throw new Error(`failed to complete native sso sign in: ${respBody}`)
    }
    const { signInToken } = JSON.parse(respBody)
    await signInWithCustomToken(getAuth(), signInToken)
  }
}
