import { Dialog, styled } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import BashClub from '@/assets/images/marketing/bash-club.png'
import DallasFlash from '@/assets/images/marketing/dallas-flash-partner.png'
import PickleLodge from '@/assets/images/marketing/pickle-lodge.png'
import PickleballGuy from '@/assets/images/marketing/Pickleball-guy.jpg'
import Scorpions from '@/assets/images/marketing/scorpions-partner.png'
import SignUpAndLoginDialog from '@/components/dialog/SignUpAndLoginDialog'
import StripeDialog from '@/components/dialog/stripe-dialog'
import { PricingPlan } from '@/components/subscribe/pricing-plan'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { usePurchaseFlow } from '@/hooks/use-purchase-flow'
import useZendesk from '@/hooks/use-zendesk'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

function ProfessionalPlanCustomPart () {
  return (
    <div className='professional-custom-part'>
      <p className='description'>
        Our Professional plan can be customized to meet your specific needs, with options to present insights in your preferred format.<br /><br />
        Facility or Club? Check out our <Link to='/partner'>Court Insights</Link> program
      </p>
      <div className='teams-section'>
        <div className='trusted-by'>Trusted by</div>
        <div className='team-images'>
          <img src={Scorpions} alt='Coachella Valley Scorpions' />
          <img src={DallasFlash} alt='Dallas Flash' />
          <img className='gray-out' src={PickleballGuy} alt='That Pickleball Guy' />
          <img className='gray-out' src={PickleLodge} alt='The Pickle Lodge' />
          <img className='gray-out' src={BashClub} alt='Bash Pickleball Club' />
        </div>
      </div>
    </div>
  )
}

export default function PricingPlansSection (props) {
  const isMobile = useMobileDetect()
  const { toggleZendeskWidget } = useZendesk()
  const purchaseFlow = usePurchaseFlow()

  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false)

  const handleCloseDialog = () => {
    setShowCreateAccountDialog(false)
  }

  return (
    <>
      <Container id='plans' className={isMobile && 'mobile'}>
        {Object.values(PRICING_PLANS_CARD_CONTENT).map((plan, i) => (
          <PricingPlan
            key={i}
            plan={plan}
            purchaseFlow={purchaseFlow}
            promptToLogin={() => {
              setShowCreateAccountDialog(true)
              if (props.preventNavigateAway) {
                props.preventNavigateAway.current = true
              }
            }}
          />
        ))}
        <div className='get-in-touch'>
          <p className='title'>Not sure which plan is right for you?</p>
          <p className='description'>
            Check out our <a href='https://help.pb.vision/articles/6882251-subscriptions-and-credits' target='_blank' rel='noreferrer'>subscriptions FAQ</a> or <span onClick={toggleZendeskWidget}>get in touch</span> - we’re happy to discuss and answer any questions you have.
          </p>
        </div>
      </Container>
      <StripeDialog isOpen={Boolean(purchaseFlow.skuToBuy)} skuToBuy={purchaseFlow.skuToBuy} onClose={() => purchaseFlow.clearSKUToBuy()} />
      <Dialog open={showCreateAccountDialog} onBackdropClick={handleCloseDialog}>
        <SignUpAndLoginDialog handleCloseDialog={handleCloseDialog} />
      </Dialog>
    </>
  )
}

export const PRICING_PLANS_CARD_CONTENT = {
  starter: {
    title: 'Starter',
    tier: 'starter',
    oldPrice: '$20',
    actionButton: {
      text: 'Subscribe now',
      action: purchaseFlow => purchaseFlow.subscribeToPlan('starter'),
      isDisabled: md => md.isSubscriber
    },
    items: [
      { text: 'Tag players to share insights' },
      { text: 'Auto rally slicing / dead time removal' },
      { text: 'Create and export highlight reels', comingSoon: true },
      { text: '10GB free video storage' },
      { text: 'View at 1080p and 30fps' },
      { text: <>Includes <a href='https://help.pb.vision/articles/6882251-subscriptions-and-credits#fqk74p8q0b1' target='_blank' rel='noreferrer'>60 Credits</a></> }
    ]
  },
  premium: {
    title: 'Premium',
    tier: 'premium',
    oldPrice: '$50',
    actionButton: {
      text: 'Subscribe now',
      action: purchaseFlow => purchaseFlow.subscribeToPlan('premium'),
      isDisabled: md => md.currentSubscription?.tier === 'premium'
    },
    everythingFromInfo: 'Everything in Starter, plus..',
    items: [
      { text: <>Explore shot by shot analysis with <strong>Shot Explorer</strong></>, gold: true },
      { text: <>Export <a href='https://help.pb.vision/articles/5971198-interpreting-pb-visions-advanced-stats' target='_blank' rel='noreferrer'>advanced stats</a></> },
      { text: 'Unlimited free video storage' },
      { text: 'View at 4k and 60fps' },
      { text: <>Includes <a href='https://help.pb.vision/articles/6882251-subscriptions-and-credits#fqk74p8q0b1' target='_blank' rel='noreferrer'>180 Credits</a> - 3x more!</> }
    ]
  },
  professional: {
    title: 'Professional',
    tier: 'professional',
    price: 'Get in touch',
    billing: 'Pro Teams, Facilities, and Coaches',
    actionButton: {
      text: 'Contact us',
      action: (purchaseFlow, toggleZendeskWidget) => toggleZendeskWidget()
    },
    items: [],
    customSection: <ProfessionalPlanCustomPart />
  }
}

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  flexWrap: 'wrap',
  marginBottom: 80,

  '& .professional-custom-part': {
    ...column,
    flex: 1,
    '& .description': {
      marginBottom: 'auto',
      marginTop: '10px',
      color: COLORS.black,
      fontWeight: 400,
      lineHeight: '138%',
      fontSize: '20px'
    },
    '& .team-images': {
      ...row,
      gap: '10px',
      marginBlock: '10px',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& img': {
        width: '75px',
        aspectRatio: 1
      },
      '& .gray-out': {
        filter: 'grayscale(1)'
      }
    },
    '& .typeform-button': {
      background: 'no-repeat',
      fontWeight: '600',
      lineHeight: '138%',
      fontSize: '20px !important',
      border: 'none',
      marginLeft: '7px',
      color: '#00d824',
      cursor: 'pointer'
    }
  },
  '& .get-in-touch': {
    textAlign: 'center',
    paddingTop: '72px',
    '& .title': {
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: '34.56px',
      letterSpacing: '-1px',
      marginBottom: '16px'
    },
    '& .description': {
      fontSize: '24px',
      lineHeight: '25.29px',
      '& span': {
        color: COLORS['primary-500'],
        cursor: 'pointer'
      }
    }
  },
  '&.mobile': {
    gap: '16px',
    fontSize: '14px',
    '& .professional-custom-part': {
      '& .description': {
        marginBottom: '50px',
        fontSize: '16px'
      },
      '& .typeform-button': {
        fontSize: '16px !important'
      }
    },
    '& .get-in-touch': {
      padding: '32px 28px',
      '& .title': {
        fontSize: '24px',
        lineHeight: '24.56px'
      },
      '& .description': {
        color: COLORS.black,
        fontWeight: 400,
        lineHeight: '138%',
        fontSize: '16px'
      }
    }
  }
})
