import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/CheckCircle'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RemoveIcon from '@mui/icons-material/RemoveCircle'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import PBVisionLogo from '@/assets/pb-vis-horiz.svg?react'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

// Styled Container
const Container = styled('div')({
  color: COLORS['neutral-700'],
  maxWidth: '868px',
  margin: 'auto',
  fontSize: '16px',
  marginBottom: 152,
  '& .table': {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: `1px solid ${COLORS['neutral-400']}`
  },
  '& .title': {
    color: COLORS['neutral-800'],
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '108%',
    letterSpacing: -1,
    marginTop: 72,
    marginBottom: 32,
    '& span': {
      fontStyle: 'italic'
    }
  },
  '& .header': {
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 'bold',
    '& > div': {
      width: 'calc(100% / 3)',
      textAlign: 'center',
      padding: '12px 0',
      '&.pb': {
        borderTop: `2px solid ${COLORS['primary-400']}`,
        borderLeft: `2px solid ${COLORS['primary-400']}`,
        borderRight: `2px solid ${COLORS['primary-400']}`,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: COLORS['primary-050'],
        borderBottom: '1px solid #fff'
      },
      '&:last-of-type': {
        borderBottom: `1px solid ${COLORS.divider}`
      },
      '& svg': {
        maxWidth: 154,
        margin: '0 8px'
      }
    }
  },
  '& .row': {
    display: 'flex',
    alignItems: 'stretch',
    '& > div': {
      display: 'flex',
      width: 'calc(100% / 3)',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: `1px solid ${COLORS.divider}`,
      '&.value-pb': {
        borderBottom: `1px solid ${COLORS['primary-050']}`,
        borderLeft: `2px solid ${COLORS['primary-400']}`,
        borderRight: `2px solid ${COLORS['primary-400']}`
      },
      '& svg': {
        marginBottom: 8
      }
    },
    '&:nth-child(odd) > div.value-pb': {
      backgroundColor: COLORS['primary-050'],
      borderBottom: '1px solid #fff'
    },
    '&:last-of-type': {
      '& > div': {
        borderBottom: 'none',
        '&.value-pb': {
          borderBottom: `2px solid ${COLORS['primary-400']}`,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          paddingBottom: 16
        }
      }
    }
  },
  '& .label': {
    fontWeight: 'bold',
    paddingLeft: '16px'
  },
  '& .value-pb, .value-sv': {
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 16px'
  },
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'bottom',
      marginLeft: 8,
      marginBottom: '0 !important'
    }
  },
  '& .green': {
    color: COLORS['primary-400']
  },
  '& .red': {
    color: COLORS['danger-500']
  },
  '& .gray': {
    color: COLORS['neutral-400']
  },
  '&.mobile': {
    maxWidth: '100%',
    marginBottom: 80
  }
})

// Row Data
const rows = [
  { label: 'AI-Powered Video Analysis', pbVisionIcon: 'yes', swingVisionIcon: 'yes' },
  {
    label: 'Actionable Insights',
    pbVisionIcon: 'yes',
    pbVisionText: <div><b>In-depth</b> learning tools and stats (e.g., kitchen arrival, shot quality assessment)</div>,
    swingVisionIcon: 'missing',
    swingVisionText: 'Minimal stats (e.g., speed, stroke type)'
  },
  { label: 'Shot Filtering', pbVisionIcon: 'yes', swingVisionIcon: 'no' },
  { label: 'Primary Sport', pbVisionIcon: 'yes', pbVisionText: 'Pickleball', swingVisionIcon: 'missing', swingVisionText: 'Tennis' },
  { label: 'Automatic Highlights', pbVisionIcon: 'yes', swingVisionIcon: 'no' },
  { label: '3D Trajectory Maps', pbVisionIcon: 'yes', swingVisionIcon: 'no' },
  { label: 'Real-Time Shot Speed', pbVisionIcon: 'no', swingVisionIcon: 'yes', swingVisionText: 'requires Apple Watch' },
  {
    label: 'Device Compatibility',
    pbVisionText: <>1. iOS<br />2. Android<br /> 3. Web Browser</>,
    swingVisionText: '1. iOS only'
  },
  {
    label: 'Subscription Model',
    pbVisionIcon: 'yes',
    pbVisionText: '15 games per month (detailed stats; best for learning; unused credits rollover)',
    swingVisionIcon: 'yes',
    swingVisionText: '30 hours per month (minimal stats; best for cheap storage; unused hours forfeited)'
  },
  {
    label: 'Club/Facility Solutions',
    pbVisionIcon: 'yes',
    pbVisionText: <>Yes (automated, affordable & weatherproof)<br /><Link to='https://pb.vision/partner'>Learn more here<OpenInNewIcon className='green' /></Link></>,
    swingVisionIcon: 'no',
    swingVisionText: <>Limited<br />(manual iPhone only)</>
  }
]

const getIcon = (type) => {
  if (type === 'yes') return <CheckIcon className='green' />
  if (type === 'no') return <CancelIcon className='red' />
  if (type === 'missing') return <RemoveIcon className='gray' />
  return null
}

const ComparisonTable = () => {
  const isMobile = useMobileDetect()
  const location = useLocation()

  return (
    <Container
      id='comparison-table' className={cls(isMobile && 'mobile')} ref={(el) => {
        if (location.hash === '#comparison-table') {
          setTimeout(() => {
            el?.scrollIntoView()
          }, 0)
        }
      }}
    >
      <div className='title'>
        How we <span>stack</span> up ... Unwind our features 😅
      </div>
      <div className='table'>
        <div className='header'>
          <div />
          <div className='pb'><PBVisionLogo /></div>
          <div>SwingVision</div>
        </div>
        {rows.map((row, index) => (
          <div key={index} className='row'>
            <div className='label'>{row.label}</div>
            <div className='value-pb'>{getIcon(row.pbVisionIcon)}{row.pbVisionText}</div>
            <div className='value-sv'>{getIcon(row.swingVisionIcon)}{row.swingVisionText}</div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default ComparisonTable
