import { styled, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useUserHasFlag } from '@/store/auth'
import { clearWebViewCommands } from '@/store/controls'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { isInMobileAppWebview, notifyChangeHostname } from '@/utils/mobile-app-communication'

function isValidURL (url) {
  const defaultHost = 'pbv-dev.web.app'

  function checkPRLink (url) {
    const prefix = 'pbv-dev--pr-'
    const suffix = '.web.app'
    return url.indexOf(prefix) === 0 && url.slice(-suffix.length) === suffix
  }

  try {
    if (url === defaultHost) {
      return true
    }

    if (url.includes('pbv-dev--pr-')) {
      return checkPRLink(url)
    }

    const newUrl = new URL(url)
    const { protocol } = newUrl
    return protocol === 'http:' || protocol === 'https:'
  } catch {
    return false
  }
}

function ChangeWebViewBaseUrl () {
  // When using local Android Emulator to test against local application, use emulator host IP 10.0.2.2, e.g. http://10.0.2.2:3000
  // and make sure debug APK supports HTTP to avoid net::ERR_CLEARTEXT_NOT_PERMITTED error
  const [baseUrl, setBaseUrl] = useState('')

  const defaultBaseUrl = 'https://pbv-dev.web.app'

  const handleInputChange = (event) => {
    const inputValue = event.target.value.trim()
    setBaseUrl(inputValue)
  }

  return (
    <>
      <p>Current URL: {(window.location.origin)}</p>
      <TextField
        variant='outlined'
        value={baseUrl}
        onChange={handleInputChange}
        placeholder='Enter new base URL'
      />
      <span>*PR links must start with “pbv-dev--pr-” and end with “.web.app”.</span>
      <span>*Other links must start with “http://” or “https://”.</span>
      <Button
        disabled={!isValidURL(baseUrl)}
        className='green'
        onClick={() => notifyChangeHostname(baseUrl)}
      >
        Change
      </Button>
      <Button
        disabled={baseUrl === defaultBaseUrl}
        className='green'
        onClick={() => {
          setBaseUrl(defaultBaseUrl)
          notifyChangeHostname(defaultBaseUrl)
        }}
      >
        Reset to Default
      </Button>
    </>
  )
}

function ReceivedCommandsHistory () {
  const history = useSelector((state) => state.controls.webviewCommands)
  const dispatch = useDispatch()

  return (
    <div className='webview-history'>
      <Button
        className='gray'
        onClick={() => dispatch(clearWebViewCommands())}
      >
        Clear history
      </Button>
      {history.map((command, index) => (
        <div style={{ ...row, alignItems: 'center' }} key={index}>
          <span style={{ textWrap: 'wrap', maxWidth: 200, wordBreak: 'break-all' }}>{command.name}</span>
          <span style={{ textWrap: 'nowrap' }}>| {command.time}</span>
        </div>
      ))}
    </div>
  )
}

export default function Test () {
  const isMobile = useMobileDetect()
  const isInWebview = isInMobileAppWebview() || 1
  const isTester = useUserHasFlag('tester')

  return (
    <Container className={isMobile && 'mobile'}>
      <h2>Test</h2>
      {isTester && <p>Visible only on dev server for authorized testers like you.</p>}
      {!isTester && <p>This menu is only usable by authorized testers (your account is not currently included).</p>}
      {isTester && isInWebview && (
        <SingleSection title='WebView'>
          <SingleOption title='Change base URL'>
            <ChangeWebViewBaseUrl />
          </SingleOption>
          <SingleOption title='Received commands history'>
            <ReceivedCommandsHistory />
          </SingleOption>
        </SingleSection>
      )}
    </Container>
  )
}

const SingleSection = (props) => {
  return (
    <div className='section'>
      <h3>{props.title}</h3>
      {props.children}
    </div>
  )
}
const SingleOption = (props) => {
  return (
    <div className='option'>
      <p>{props.title}</p>
      <div>
        {props.children}
      </div>
    </div>
  )
}

const Container = styled('div')({
  ...column,
  gap: 20,
  '& .section': {
    ...column,
    gap: 10,
    backgroundColor: COLORS['neutral-100'],
    alignItems: 'flex-start',
    '& h3': {
      marginBottom: 10
    },
    border: `1px solid ${COLORS['neutral-300']}`,
    borderRadius: 10,
    padding: 10,
    color: 'black'
  },
  '& .option': {
    ...row,
    width: '100%',
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS['neutral-300']}`,
    '& > p': {
      width: '20%',
      fontSize: 14,
      borderRight: `1px solid ${COLORS['neutral-300']}`
    },
    '& > div': {
      ...column,
      gap: 10,
      width: '100%',
      paddingInline: 10
    },
    '& span': {
      fontSize: 12,
      color: COLORS['neutral-700']
    }
  },
  '& .webview-history': {
    ...column,
    gap: 5,
    maxHeight: 250,
    overflowY: 'auto',
    '& > div': {
      ...row,
      justifyContent: 'space-between',
      paddingInline: 5,
      '&:nth-child(odd)': {
        backgroundColor: COLORS['neutral-200']
      }
    }
  },
  '&.mobile': {
    '& .option': {
      ...column,
      '& > p': {
        width: '100%',
        borderRight: 'none',
        borderBottom: `1px solid ${COLORS['neutral-300']}`
      },
      '& > div': {
        paddingInline: 0,
        paddingBlock: 10
      }
    }
  }
})
