// This slice stores data for shot filters, persisting them with local storage
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { initialFilters } from '@/utils/shot-filter'

export const sliceName = 'shotFilters'

const shotFiltersSlice = createSlice({
  name: sliceName,
  initialState: {
    ...initialFilters,
    vid: null
  },
  reducers: {
    setAllFilters: (state, action) => {
      // Resetting state to initial value and then applying the payload
      // in order to make sure all of the filters are present
      Object.assign(state, initialFilters, action.payload)
    },
    setShotFilters: (state, action) => {
      const { vid, type, value } = action.payload

      state[type] = value
      state.vid = vid
    },
    clearFilters: (state, action) => {
      Object.assign(state, initialFilters)
      state.vid = null
    }
  }
})

export const selectShotFilters = state => state[sliceName]
export const useShotFilters = () => useSelector(selectShotFilters)
export const { reducer } = shotFiltersSlice
export const { setAllFilters, setShotFilters, clearFilters } = shotFiltersSlice.actions
